import { createAxiosInstance, createCallApi } from '@/api/creator';
import {makeConfig} from "@/api";

// baseURL 전달 받아서 서버 인스턴스 생성
const serverInstance = createAxiosInstance(process.env?.VUE_APP_LLM_SERVER_URL ? process.env.VUE_APP_LLM_SERVER_URL : '');

/**
 * config 생성
 * @param payload
 * @return {{}|{headers, responseType}}
 */
const makeLLMConfig = payload => {
	if (payload.method !== 'POST' && payload.responseType !== 'blob') {
		return {};
	}

	return makeConfig(payload);
};

export const llmApi = {
	serverInstance,
	callApi: payload => createCallApi(payload, serverInstance, makeLLMConfig(payload)),
};
