import { EventBus } from '@/event-bus';
import { espApi } from '@/api';
import { responseConsoleLogger, errorPopup } from '../../api/error-handle';

/**
 * 라우팅 정보 업데이트
 * @param commit
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const SET_ROUTING_INFO = async ({ commit }) => {
  await espApi
    .getRoutingInfoList()
    .then(res => {
      commit('setRoutingInfo', res.data.data); // API URL 목록 저장
    })
    .catch(error => {
      return error;
    });
};

/**
 * routing 정보 조회
 * @param state - store state
 * @param payload
 * @return {T}
 */
export const getRoutingInfo = ({ state }, payload) => {
  let routingInfo = state.routingInfos.find(d => d.actionNm === payload.actionname);

  // actionname이 stor routingInfos 에 없는 경우( 예) 미표시된 API URL )
  if (payload.actionname !== null && payload.actionname !== undefined && routingInfo === undefined) {
    const message = `
					캐시 새로고침 또는 로그아웃 후 재시도 하세요. </br>
					(오류 재발시 관리자에게 문의하세요.) </br>
					not found action name -> "${payload.actionname}"
					`;
    EventBus.$emit('app:errMsgOn', message);
  }
  return routingInfo;
};

/**
 * ESP API 호출
 * @param state
 * @param payload
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const CALL_API = async ({ state }, payload) => {
  try {
    const routingInfo = getRoutingInfo({ state }, payload);
    let requestPath = routingInfo.path;
    if (payload?.path) {
      if (payload.path.startsWith('/')) {
        requestPath += payload.path;
      } else {
        requestPath += '/' + payload.path;
      }
    }

    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: requestPath,
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionname: payload.actionname,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
      onDownloadProgress: payload.onDownloadProgress,
    };

    payload.loading && EventBus.$emit('app:progress', true);

    const res = await espApi.callApi(requestParam);

    // 디버그 로거
    responseConsoleLogger(payload.actionname, res);

    payload.loading && EventBus.$emit('app:progress', false);
    return res;
  } catch (error) {
    payload.loading && EventBus.$emit('app:progress', false);

    // 에러 팝업 처리
    errorPopup(error, payload);
    return error.response || error;
  }
};

/**
 * JWT 토큰 갱신
 * @param commit
 * @return {Promise<*|e.Response<any, Record<string, any>>|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse|AxiosResponse<any>>}
 * @constructor
 */
export const REFRESH_TOKEN = async ({ commit }) => {
  try {
    const res = await espApi.getToken();
    if (res.data.header.resCode === 'success') {
      const promises = [
        commit('setAuthId', res.data.data.authId),
        commit('setLoginId', res.data.data.loginId),
        commit('setLoginNm', res.data.data.loginNm),
        commit('setAccessToken', res.data.data.accessToken),
        commit('setRefreshToken', res.data.data.refreshToken),
        commit('setIsKeepLogin', res.data.data.isKeepLogin),
        commit('setUserInfo', res.data.data.userView),
        commit('setMemberState', res.data.data.memberState),
        commit('setPersistedAccessToken', res.data.data.accessToken),
      ];
      await Promise.all(promises);
    }
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * 시스템 설정 초기화
 * @param commit
 * @param payload
 * @return {Promise<AxiosResponse<*>|*|e.Response|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|AuthenticatorResponse>}
 * @constructor
 */
export const INIT_SYSTEM_SETTING = async ({ commit }) => {
  try {
    const res = await espApi.getSystemSettings();
    if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
      commit('setSystemList', res.data.data);
    }
    return res;
  } catch (error) {
    return error;
  }
};

/**
 * 테마 설정 초기화
 * @param commit
 * @return {Promise<AxiosResponse<*>|*>}
 * @constructor
 */
export const INIT_THEME_SETTING = async ({ commit }) => {
  try {
    const res = await espApi.getThemeSettings();
    if (res && res.data && res.data.header && res.data.header.resCode === 'success') {
      commit('setThemeSetting', res.data.data);
    }
    return res;
  } catch (error) {
    return error;
  }
};
