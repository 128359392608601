import { EventBus } from '@/event-bus';
import { llmApi } from '@/api';
import { getRoutingInfo } from './esp';
import { otherResponseConsoleLogger } from '../../api/error-handle';

/**
 * LLM API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_LLM_API = async ({ state }, payload) => {
  let res;
  try {
    const routingInfo = getRoutingInfo({ state }, payload);
    let requestPath = routingInfo.path;
    if (payload?.path) {
      if (payload.path.startsWith('/')) {
        requestPath += payload.path;
      } else {
        requestPath += '/' + payload.path;
      }
    }
    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: requestPath,
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionname: payload.actionname,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
    };

    payload.loading && EventBus.$emit('app:progress', true);

    res = await llmApi.callApi(requestParam);

    otherResponseConsoleLogger(payload.actionname, res);

    payload.loading && EventBus.$emit('app:progress', false);
    return res;
  } catch (error) {
    if (payload.loading) EventBus.$emit('app:progress', false);
    return error.response || error;
  }
};
