import { createAxiosInstance, createCallApi } from '@/api/creator';
import {makeConfig} from "@/api";

// baseURL 전달 받아서 서버 인스턴스 생성
const serverInstance = createAxiosInstance(process.env?.VUE_APP_ESP_REPORT_SERVER_URL ? process.env.VUE_APP_ESP_REPORT_SERVER_URL : '');

export const reportApi = {
	serverInstance,
	callApi: payload => createCallApi(payload, serverInstance, makeConfig(payload)),
};
