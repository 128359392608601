import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/sweetAlert.css';

Vue.use(VueSweetalert2);

export default {
  install(Vue) {

    // 일반 메세지 Alert
    Vue.prototype.$_Msg = function(contents, obj = {}) {
      const icon = Object.prototype.hasOwnProperty.call(obj, 'icon') ? obj.icon : null;
      const title = Object.prototype.hasOwnProperty.call(obj, 'title') ? obj.title : '알림';
      const footer = Object.prototype.hasOwnProperty.call(obj, 'footer') ? obj.footer : null;
      const showCloseButton = Object.prototype.hasOwnProperty.call(obj, 'showCloseButton') ? obj.showCloseButton : true;
      const showCancelButton = Object.prototype.hasOwnProperty.call(obj, 'showCancelButton') ? obj.showCancelButton : false;
      const confirmButtonText = Object.prototype.hasOwnProperty.call(obj, 'confirmButtonText') ? obj.confirmButtonText : '확인';
      const allowOutsideClick = Object.prototype.hasOwnProperty.call(obj, 'allowOutsideClick') ? obj.allowOutsideClick : false;
      const root = document.querySelector(':root');
      const variables = getComputedStyle(root);

      return this.$swal.fire({
        icon: icon,
        title: title,
        html: contents,
        footer: footer,
        showCloseButton: showCloseButton,
        confirmButtonText: confirmButtonText,
        showCancelButton: showCancelButton,
        allowOutsideClick: allowOutsideClick,
        confirmButtonColor: variables.getPropertyValue('--themeColor'),
        customClass: {
          container: 'sweet-container-default',
        },
        heightAuto: false,
      }).then(rst => {
        return rst.isConfirmed;
      });
    };

    // 확인 메세지 Alert
    Vue.prototype.$_Confirm = function(contents, obj = {}) {
      const icon = Object.prototype.hasOwnProperty.call(obj, 'icon') ? obj.icon : null;
      const title = Object.prototype.hasOwnProperty.call(obj, 'title') ? obj.title : '알림';
      const footer = Object.prototype.hasOwnProperty.call(obj, 'footer') ? obj.footer : null;
      const showCloseButton = Object.prototype.hasOwnProperty.call(obj, 'showCloseButton') ? obj.showCloseButton : true;
      const showCancelButton = Object.prototype.hasOwnProperty.call(obj, 'showCancelButton') ? obj.showCancelButton : true;
      const confirmButtonText = Object.prototype.hasOwnProperty.call(obj, 'confirmButtonText') ? obj.confirmButtonText : '확인';
      const cancelButtonText = Object.prototype.hasOwnProperty.call(obj, 'cancelButtonText') ? obj.cancelButtonText : '취소';
      const allowOutsideClick = Object.prototype.hasOwnProperty.call(obj, 'allowOutsideClick') ? obj.allowOutsideClick : false;
      const root = document.querySelector(':root');
      const variables = getComputedStyle(root);

      return this.$swal.fire({
        icon: icon,
        title: title,
        html: contents,
        footer: footer,
        showCloseButton: showCloseButton,
        confirmButtonText: confirmButtonText,
        showCancelButton: showCancelButton,
        cancelButtonText: cancelButtonText,
        allowOutsideClick: allowOutsideClick,
        confirmButtonColor: variables.getPropertyValue('--themeColor'),
        cancelButtonColor: '#FFF',
        reverseButtons: false,
        customClass: {
          container: 'sweet-container-default',
        },
        heightAuto: false,
      }).then(rst => {
        return rst.isConfirmed;
      });
    };

    // Toast 메세지 Alert
    Vue.prototype.$_Toast = function(contents, obj = {}) {
      let position = Object.prototype.hasOwnProperty.call(obj, 'position') ? obj.position : 'bottom';
      let icon = Object.prototype.hasOwnProperty.call(obj, 'icon') ? obj.icon : null;
      let showConfirmButton = Object.prototype.hasOwnProperty.call(obj, 'showConfirmButton') ? obj.showConfirmButton : false;
      let timer = Object.prototype.hasOwnProperty.call(obj, 'timer') ? obj.timer : 3000; // 3초 후 자동 닫힘
      return this.$swal.fire({
        toast: true,
        position: position,
        icon: icon,
        title: contents,
        showConfirmButton: showConfirmButton,
        timer: timer,
        customClass: {
          container: 'sweet-container-toast',
          popup: 'sweet-popup-toast',
          header: 'sweet-header-toast',
          title: 'sweet-title-toast',
        },
      }).then(rst => {
        return rst.isDismissed;
      });
    };
  }
}