import DefaultReport from '@/pages/report/wizard-report/default-report-view.vue';
import Top from '@/layouts/top.vue';
import Bottom from '@/layouts/bottom.vue';
import LeftMenu from '@/layouts/left-menu.vue';
import Breadcrumbs from '@/layouts/breadcrumbs.vue';
import DateColumnReport from '@/pages/report/wizard-report/date-column-report.vue';
import ReportDashboard from "@/pages/report/dashboard/list.vue";

export default [
  {
    path: '/data-report/:menuid',
    name: 'DefaultReport',
    components: { default: DefaultReport, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true },
  },
  {
    path: '/date-column-report/:menuid',
    name: 'DateColumnReport',
    components: { default: DateColumnReport, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true },
  },
  {
    path: '/report/dashboard/list/:dashboardTemplateId/:mainFlag',
    name: 'ReportDashboard',
    components: { default: ReportDashboard, top: Top, bottom: Bottom, left: LeftMenu, breadcrumbs: Breadcrumbs },
    meta: { requiresAuth: true },
  },
];
