<!--
  PACKAGE_NAME : src\components\devextreme
  FILE_NAME : esp-dx-data-grid
  AUTHOR : devyoon91
  DATE : 2024-09-20
  DESCRIPTION :
-->
<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <div class="cusmain-table-wrap">
      <DxDataGrid
        id="dxDataGrid"
        class="grid-box"
        :allow-column-reordering="dataGrid.allowColumnReordering"
        :allow-column-resizing="dataGrid.allowColumnResizing"
        :column-auto-width="dataGrid.columnAutoWidth"
        :column-min-width="dataGrid.columnMinWidth"
        :column-resizing-mode="dataGrid.columnResizingMode"
        :data-source="dataGrid.dataSource"
        :focused-row-enabled="dataGrid.focusedRowEnabled"
        :height="grid.height"
        :hover-state-enabled="dataGrid.hoverStateEnabled"
        :key-expr="dataGrid.keyExpr"
        :no-data-text="noDataText()"
        :ref="dataGrid.refName"
        :row-alternation-enabled="dataGrid.rowAlternationEnabled"
        :selected-row-keys="onSelectedRowKeys()"
        :show-borders="dataGrid.showBorders"
        :show-column-headers="dataGrid.showColumnHeaders"
        :show-column-lines="dataGrid.showColumnLines ? dataGrid.showColumnLines : true"
        :show-row-lines="dataGrid.showRowLines"
        :width="dataGrid.width"
        :word-wrap-enabled="dataGrid.wordWrapEnabled"
        @cell-hover-changed="onCellHoverChanged"
        @cell-prepared="onCellPrepared"
        @content-ready="onContentReady"
        @context-menu-preparing="onContextMenuPreparing"
        @edit-canceled="onEditCanceled"
        @editing-start="onEditingStart"
        @editor-prepared="onEditorPrepared"
        @editor-preparing="onEditorPreparing"
        @exporting="onExportingCheck"
        @focused-row-changed="onFocusedRowChanged"
        @focused-row-changing="onFocusedRowChanging"
        @init-new-row="onInitNewRow"
        @option-changed="onOptionChanged"
        @reorder="onReorder"
        @row-click="onRowClick"
        @row-inserted="onRowInserted"
        @row-inserting="onRowInserting"
        @row-prepared="onRowPrepared"
        @row-removed="onRowRemoved"
        @row-updated="onRowUpdated"
        @row-updating="onRowUpdating"
        @row-validating="onRowValidating"
        @saving="onSaving"
        @selection-changed="onSelectionChanged"
        @toolbar-preparing="onToolbarPreparing"
        @initialized="onInitialized"
        :cache-enabled="false"
      >
        <!-- 행 드래그 관련 -->
        <DxRowDragging
          v-if="dataGrid.dragging"
          :allow-reordering="dataGrid.dragging.allowReordering"
          :on-reorder="onReorder"
          :drop-feedback-mode="dataGrid.dragging.dropFeedbackMode"
        />

        <!-- 항목 출력 여부 관련 설정 -->
        <DxColumnChooser v-if="dataGrid.columnChooser" :enabled="dataGrid.columnChooser.enabled" />

        <!-- 그룹핑 설정 -->
        <DxGrouping
          v-if="dataGrid.grouping"
          :context-menu-enabled="dataGrid.grouping.contextMenuEnabled"
          :auto-expand-all="dataGrid.grouping.autoExpandAll"
          :allow-collapsing="dataGrid.grouping.allowCollapsing"
          :expand-mode="dataGrid.grouping.expandMode"
        />

        <!-- 그룹핑 패널 설정 -->
        <DxGroupPanel v-if="dataGrid.groupPanel" :visible="dataGrid.groupPanel.visible" />
        <!-- or "auto" -->

        <!-- 로딩바 표시 유무 설정 -->
        <DxLoadPanel v-if="dataGrid.loadPanel" :enabled="dataGrid.loadPanel.enabled" />

        <!-- 순서 설정 -->
        <DxSorting v-if="dataGrid.sorting" :mode="dataGrid.sorting.mode" />

        <!-- 스크롤 설정 -->
        <DxScrolling
          v-if="dataGrid.scrolling"
          :column-rendering-mode="dataGrid.scrolling.columnRenderingMode"
          :mode="dataGrid.scrolling.mode"
          :row-rendering-mode="dataGrid.scrolling.rowRenderingMode"
          :preload-enabled="dataGrid.scrolling.preloadEnabled"
          :render-async="dataGrid.scrolling.renderAsync"
          :scroll-by-content="dataGrid.scrolling.scrollByContent"
          :scroll-by-thumb="dataGrid.scrolling.scrollByThumb"
          :show-scrollbar="dataGrid.scrolling.showScrollbar"
          :use-native="dataGrid.scrolling.useNative"
        />

        <!-- 서버사이드 설정 -->
        <DxRemoteOperations
          :filtering="dataGrid.remoteOperations.filtering"
          :sorting="dataGrid.remoteOperations.sorting"
          :grouping="dataGrid.remoteOperations.grouping"
          :paging="dataGrid.remoteOperations.paging"
        />

        <!-- 페이징 처리 설정 -->
        <DxPaging :enabled="dataGrid.paging?.enabled ?? paging.enabled" :page-size="paging.pageSize" :page-index="paging.pageIndex" />
        <!-- 페이저 설정 -->
        <!-- show-page-size-selector="false", 페이지 사이즈 선택은 커스텀으로 만든게 있으므로 무조건 false  -->
        <DxPager
          :visible="dataGrid.pager.visible"
          :show-page-size-selector="false"
          :allowed-page-sizes="paging.allowedPageSizes"
          :display-mode="dataGrid.pager.displayMode"
          :show-info="dataGrid.pager.showInfo"
          :show-navigation-buttons="dataGrid.pager.showNavigationButtons"
          :info-text="dataGrid.pager.infoText"
        />

        <!-- 필터 설정 -->
        <DxFilterRow :visible="dataGrid.filterRow.visible">
          <DxOperationDescriptions
            :contains="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.contains"
            :between="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.between"
            :endsWith="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.endsWith"
            :equal="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.equal"
            :greaterThan="
              dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.greaterThan
            "
            :greaterThanOrEqual="
              dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.greaterThanOrEqual
            "
            :lessThan="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.lessThan"
            :lessThanOrEqual="
              dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.lessThanOrEqual
            "
            :notContains="
              dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.notContains
            "
            :notEqual="dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.notEqual"
            :startsWith="
              dataGrid.filterRow && dataGrid.filterRow.operationDescriptions && dataGrid.filterRow.operationDescriptions.startsWith
            "
          />
        </DxFilterRow>

        <!-- 헤더필터 설정 -->
        <DxHeaderFilter :visible="dataGrid.headerFilter.visible" />
        <!-- 수정모드 -->
        <DxEditing
          :allow-updating="dataGrid.editing.allowUpdating"
          :allow-deleting="dataGrid.editing.allowDeleting"
          :allow-adding="dataGrid.editing.allowAdding"
          :mode="dataGrid.editing.mode"
          :select-text-on-edit-start="dataGrid.editing.selectTextOnEditStart"
          :start-edit-action="dataGrid.editing.startEditAction"
          :refresh-mode="dataGrid.editing.refreshMode"
          :new-row-position="dataGrid.editing.newRowPosition"
          :confirm-delete="dataGrid.editing.confirmDelete"
          :edit-column-name="dataGrid.editing.editColumnName"
          :edit-row-key="dataGrid.editing.editRowKey"
          :form="dataGrid.editing.form"
          :popup="dataGrid.editing.popup"
          :use-icons="dataGrid.editing.useIcons"
          :texts="dataGrid.editing.texts === undefined ? null : dataGrid.editing.texts"
        >
        </DxEditing>

        <!-- 로우 선택 설정 -->
        <DxSelection
          v-if="dataGrid.selecting"
          :mode="dataGrid.selecting.mode"
          :show-check-boxes-mode="dataGrid.selecting.showCheckBoxesMode"
          :select-all-mode="dataGrid.selecting.selectAllMode"
          :deferred="dataGrid.selecting.deferred"
        />

        <!-- 엑셀 다운로드 설정 -->
        <DxExport
          v-if="showActionButtons.excel"
          :enabled="showActionButtons.excel.enabled === undefined ? true : showActionButtons.excel.enabled"
          :allow-export-selected-data="
            showActionButtons.excel.allowExportSelectedData === undefined ? true : showActionButtons.excel.allowExportSelectedData
          "
          :texts="showActionButtons.excel.exportButtonText ? showActionButtons.excel.exportButtonText : exportButtonText"
        />

        <!-- 검색 패널 설정 -->
        <DxSearchPanel
          v-if="dataGrid.searchPanel"
          :isActive="dataGrid.searchPanel.isActive"
          :highlightCaseSensitive="dataGrid.searchPanel.highlightCaseSensitive"
          :highlightSearchText="dataGrid.searchPanel.highlightSearchText"
          :searchVisibleColumnsOnly="dataGrid.searchPanel.searchVisibleColumnsOnly"
          :text="dataGrid.searchPanel.text"
          :visible="dataGrid.searchPanel.visible"
          :placeholder="dataGrid.searchPanel.placeholder"
          :width="dataGrid.searchPanel.width"
        />

        <!-- 항목 설정 -->
        <template v-for="(column, index) in dataGrid.columns">
          <template v-if="column.multiHeaderNm">
            <DxColumn :key="index" :caption="$_msgContents(column.i18n, { defaultValue: column.multiHeaderNm })">
              <DxColumn
                v-for="(column2, index2) in column.columns"
                :key="`${index}_${index2}`"
                :alignment="column2.alignment"
                :allow-editing="column2.allowEditing"
                :allow-exporting="column2.allowExporting"
                :allow-filtering="column2.allowFiltering"
                :allow-grouping="column2.allowGrouping"
                :allow-header-filtering="column2.allowHeaderFiltering"
                :allow-sorting="column2.allowSorting"
                :calculate-sort-value="column2.calculateSortValue"
                :calculate-cell-value="column2.calculateCellValue"
                :calculate-display-value="column2.calculateDisplayCellValue"
                :caption="$_msgContents(column2.i18n, { defaultValue: column2.caption })"
                :cell-template="column2.cellTemplate"
                :css-class="column2.cssClass"
                :customize-text="column2.customizeText"
                :data-field="column2.dataField"
                :data-type="column2.dataType"
                :edit-cell-template="column2.editCellTemplate"
                :editor-options="column2.editorOptions"
                :editor-type="column2.editorType"
                :filterValue="column2.filterValue"
                :fixed="column2.fixed"
                :fixed-position="column2.fixedPosition"
                :format="column2.format"
                :group-index="column2.groupIndex"
                :header-cell-template="column2.headerCellTemplate"
                :height="column2.height"
                :set-cell-value="column2.setCellValue"
                :sort-order="column2.sortOrder"
                :validation-rules="column2.validationRules"
                :visible="column2.visible"
                :width="column2.width"
                :min-width="column2.minWidth === 0 || column2.minWidth ? column2.minWidth : 50"
              >
                <!-- selectBox 옵션 -->
                <DxLookup
                  v-if="column2.lookup"
                  :data-source="column2.lookup.dataSource"
                  :display-expr="column2.lookup.displayExpr"
                  :value-expr="column2.lookup.valueExpr"
                  :allow-clearing="column2.lookup.allowClearing"
                />

                <!-- 헤더필터 설정 -->
                <DxHeaderFilter v-if="column2.headerFilter" :data-source="column2.headerFilter.dataSource" />

                <!-- 필수조건 설정 -->
                <DxRequiredRule
                  v-if="column2.requiredRule"
                  :message="$_msgContents(column2.requiredRule.i18n, { defaultValue: column2.requiredRule.message })"
                />
                <!-- 패턴 규칙 설정 -->
                <DxPatternRule v-if="column2.patternRule" :pattern="column2.patternRule.pattern" :message="column2.patternRule.message" />
                <!-- 커스텀 규칙 설정 -->
                <DxCustomRule
                  v-if="column2.customRule"
                  type="custom"
                  :validationCallback="column2.customRule.callback"
                  :message="column2.customRule.message"
                />
              </DxColumn>
            </DxColumn>
          </template>
          <template v-else>
            <DxColumn
              :key="index"
              :alignment="column.alignment"
              :allow-editing="column.allowEditing"
              :allow-exporting="column.allowExporting"
              :allow-filtering="column.allowFiltering"
              :allow-grouping="column.allowGrouping"
              :allow-header-filtering="column.allowHeaderFiltering"
              :allow-sorting="column.allowSorting"
              :calculate-sort-value="column.calculateSortValue"
              :calculate-cell-value="column.calculateCellValue"
              :calculate-display-value="column.calculateDisplayCellValue"
              :caption="$_msgContents(column.i18n, { defaultValue: column.caption })"
              :cell-template="column.cellTemplate"
              :css-class="column.cssClass"
              :customize-text="column.customizeText"
              :data-field="column.dataField"
              :data-type="column.dataType"
              :edit-cell-template="column.editCellTemplate"
              :editor-options="column.editorOptions"
              :editor-type="column.editorType"
              :filterValue="column.filterValue"
              :fixed="column.fixed"
              :fixed-position="column.fixedPosition"
              :format="column.format"
              :group-index="column.groupIndex"
              :header-cell-template="column.headerCellTemplate"
              :height="column.height"
              :set-cell-value="column.setCellValue"
              :sort-order="column.sortOrder"
              :validation-rules="column.validationRules"
              :visible="column.visible"
              :width="column.width"
              :min-width="column.minWidth === 0 || column.minWidth ? column.minWidth : 50"
            >
              <!-- selectBox 옵션 -->
              <DxLookup
                v-if="column.lookup"
                :data-source="column.lookup.dataSource"
                :display-expr="column.lookup.displayExpr"
                :value-expr="column.lookup.valueExpr"
                :allow-clearing="column.lookup.allowClearing"
              />

              <!-- 헤더필터 설정 -->
              <DxHeaderFilter v-if="column.headerFilter" :data-source="column.headerFilter.dataSource" />

              <!-- 필수조건 설정 -->
              <DxRequiredRule v-if="column.requiredRule" :message="column.requiredRule.message" />
              <!-- 패턴 규칙 설정 -->
              <DxPatternRule v-if="column.patternRule" :pattern="column.patternRule.pattern" :message="column.patternRule.message" />
              <!-- 커스텀 규칙 설정 -->
              <DxCustomRule
                v-if="column.customRule"
                type="custom"
                :validationCallback="column.customRule.callback"
                :message="column.customRule.message"
              />
            </DxColumn>
          </template>
        </template>

        <!-- summary -->
        <DxSummary
          v-if="dataGrid.summary"
          :calculate-custom-summary="dataGrid.summary.calculateCustomSummary"
          :group-items="dataGrid.summary.groupItems"
          :total-items="dataGrid.summary.totalItems"
          :recalculate-while-editing="dataGrid.summary.recalculateWhileEditing"
          :skip-empty-values="dataGrid.summary.skipEmptyValues"
          :texts="dataGrid.summary.texts"
        />

        <!-- toolbar -->
        <template #totalCount>
          <div class="total-count-item">
            검색결과 <span class="tet-calr1">{{ paging.totalCount }}</span> 개
          </div>
        </template>
      </DxDataGrid>
    </div>

    <DxPopup
      v-model="modal.isOpened"
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :width="modal.initData ? modal.initData.width : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="false"
      :close-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="reasonModalRef"
            v-if="modal.sendData"
            :is="modal.currentComponent"
            :modalData="modal.sendData"
            :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.$_msgContents('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => this.isOpenModal(false),
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.$_msgContents('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => this.onDownloadReason(),
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import { isSuccess, isTrue, formatDate, setGridSingleSelection } from '@/plugins/common-lib';
  import {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxSelection,
    DxLookup,
    DxFilterRow,
    DxPaging,
    DxPager,
    DxOperationDescriptions,
    DxRemoteOperations,
    DxHeaderFilter,
    DxScrolling,
    DxSorting,
    DxColumnChooser,
    DxLoadPanel,
    DxGrouping,
    DxGroupPanel,
    DxRequiredRule,
    DxExport,
    DxRowDragging,
    DxPatternRule,
    DxCustomRule,
    DxSearchPanel,
    DxSummary,
  } from 'devextreme-vue/data-grid';
  import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
  import { exportDataGrid } from 'devextreme/excel_exporter';
  import ExcelJS from 'exceljs';
  import saveAs from 'file-saver';
  import store from '@/store';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import CustomStore from 'devextreme/data/custom_store';

  export default {
    components: {
      DxSummary,
      DxToolbarItem,
      DxPopup,
      DxDataGrid,
      DxColumn,
      DxEditing,
      DxSelection,
      DxLookup,
      DxFilterRow,
      DxPaging,
      DxPager,
      DxOperationDescriptions,
      DxRemoteOperations,
      DxHeaderFilter,
      DxScrolling,
      DxSorting,
      DxRequiredRule,
      DxColumnChooser,
      DxLoadPanel,
      DxGrouping,
      DxGroupPanel,
      DxExport,
      DxRowDragging,
      DxPatternRule,
      DxCustomRule,
      DxSearchPanel,
      ModalDownloadReason,
    },
    props: {
      dataGrid: {
        default: () => ({
          apiActionNm: {
            // api 호출시 사용할 action name
            update: null,
            delete: null,
            select: null,
            merge: null,
          },
        }),
        type: Object,
      },
    },
    data() {
      return {
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        grid: {
          height: null,
        },
        showActionButtons: {
          excel: false,
          csv: false,
          select: false,
          copy: false,
          delete: false,
          customButtons: [],
        },
        callApi: this.dataGrid.callApi == null ? 'CALL_API' : this.dataGrid.callApi, //api 호출
        stylingMode: 'outlined', //outlined, underlined, filled
        selectedRowKeys: [], //선택한(체크된) 로우 키값들
        selectedRowsData: [], //선택한(체크된) 로우 데이터
        selectedCopyData: null, //카피된 데이터
        focusedRowKey: null, //클릭한 로우 id
        focusedRowData: null, //클릭한 로우 Data
        isClone: false, //복사 체크
        paging: {
          enabled: true, //페이징 사용 여부
          totalCount: 0, //페이지 totalCount
          pageIndex: 0, //페이징 인덱스
          pageSize: 20, //페이징 관련, 페이지 기본 사이즈
          allowedPageSizes: [10, 20, 30, 50, 100, 300], //페이징 관련, 허용 페이지 목록수
          pageSizeCacheFl: null, //페이지 목록수 캐시 저장 여부
          pageList: [], //페이징 관련
        },
        toolbarItems: null, //툴바 버튼 관련
        exportButtonText: {
          //엑셀 다운로드 정보
          exportTo: '엑셀다운로드',
          exportAll: '전체 다운로드',
          exportSelectedRows: '선택한 데이터 다운로드',
        },
      };
    },
    computed: {
      /** @description: 그리드 instance 정보 가져오기 */
      getGridInstance() {
        return this.$refs[this.dataGrid.refName].instance;
      },
      /** @description: grid data items */
      getItems() {
        return this.getGridInstance.getDataSource()._items;
      },
    },
    methods: {
      /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
          this.modal.contentData = null;
        }
      },
      /** @description: 팝업 창 열때 이벤트 */
      onOpenModal(componentNm, componentInitData, sendData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = sendData;
        this.isOpenModal(true);
      },
      /**
       * @description: Grid 편집모드가 켜져있다면 true
       * @returns {boolean}
       */
      hasEditData() {
        return this.getGridInstance.hasEditData();
      },
      /** @description: 그리드 선택된 행 관련 이벤트 */
      onSelectedRowKeys() {
        if (null != this.dataGrid.selectedRowKeys) {
          if (this.dataGrid.selectedRowKeys.length > 0) {
            for (let sl of this.dataGrid.selectedRowKeys) {
              this.selectedRowKeys.push(sl);
            }
          }
        }
        return this.selectedRowKeys;
      },
      /** @description: 그리드 행 순서 변경 관련 이벤트 */
      onReorder(e) {
        if (this.checkObjKeyByCustomEvent('reorder')) {
          this.$emit('reorder', e);
        } else {
          e.promise = this.processReorder(e);
        }
      },
      /** @description: 그리드 행 순서 변경 관련 */
      async processReorder(e) {
        const visibleRows = e.component.getVisibleRows();

        if (this.dataGrid.dragging && this.dataGrid.dragging.sortColumn) {
          const newOrderIndex = visibleRows[e.toIndex].data[this.dataGrid.dragging.sortColumn];

          let updateData = {};

          const key = this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id';
          updateData[key] = e.itemData[key];
          updateData[this.dataGrid.dragging.sortColumn] = newOrderIndex - 1;

          let payload = {
            actionname: this.dataGrid.apiActionNm.update,
            data: updateData,
            loading: true,
            useErrorPopup: true,
          };
          let res = await store.dispatch(this.callApi, payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            e.component.refresh();
          }
        }
      },
      /** @description: 페이징 데이터 바인딩 가져오기 */
      async bindPagingData() {
        this.paging.pageList = this.$_enums.common.pagingSizeList.values;
        this.paging.allowedPageSizes = this.paging.pageList.map(d => d.value);

        //페이지 목록수 캐시 저장 여부와 목록 캐시 여부 체크
        if (this.paging.pageSizeCacheFl || this.$store.getters.getListCacheFl) {
          await this.$_getPagingHists(this.paging);
        }
      },
      /** @description: 페이지 사이즈 변경 이벤트 */
      onChangePageSize(data) {
        if (this.dataGrid.paging.enabled) this.paging.pageSize = data.value;
      },
      /** @description: 그리드의 데이터 없을 경우 출력 */
      noDataText() {
        return this.$_msgContents('COMMON.MESSAGE.CMN_NO_DATA', { defaultValue: '데이터가 없습니다.' });
      },
      /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
      onToolbarPreparing(e) {
        if (this.checkObjKeyByCustomEvent('toolbarPreparing')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('toolbar-preparing', e);
        } else {
          const toolbarItems = e.toolbarOptions.items;
          let vm = this;

          // Adds a new item
          if (this.showActionButtons?.select) {
            toolbarItems.unshift({
              widget: 'dxButton',
              options: {
                icon: '',
                text: '조회',
                hint: '조회',
                showText: 'always',
                elementAttr: { class: 'btn_XS default filled' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.refreshData();
                },
              },
              location: 'before',
              sortIndex: 20,
            });
          }

          if (this.showActionButtons?.copy) {
            toolbarItems.unshift({
              location: 'before',
              widget: 'dxButton',
              options: {
                icon: '',
                type: 'normal',
                text: '복사',
                hint: '복사',
                elementAttr: { class: 'btn_XS default filled' },
                width: 60,
                height: 30,
                onClick: () => {
                  if (this.selectedRowsData?.length) {
                    this.selectedRowsData.forEach(element => {
                      this.isClone = true;
                      this.selectedCopyData = element;
                      this.$refs[this.dataGrid.refName].instance.addRow();
                    });
                  } else {
                    this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                  }
                },
              },
              sortIndex: 10,
            });
          }
          toolbarItems.forEach(item => {
            if (item.name === 'saveButton') {
              item.location = 'before';
              item.sortIndex = 40;
              item.options.icon = '';
              item.options.text = '저장';
              item.options.hint = '저장';
              item.showText = 'always';
              item.options.elementAttr = { class: 'btn_XS default filled' };
              item.options.width = '60';
              item.options.height = '30';
            } else if (item.name === 'addRowButton') {
              item.location = 'before';
              item.sortIndex = 30;
              item.options.icon = '';
              item.options.text = '추가';
              item.options.hint = '추가';
              item.showText = 'always';
              item.options.elementAttr = { class: 'btn_XS default filled add1' };
              item.options.width = '60';
              item.options.height = '30';
            } else if (item.name === 'revertButton') {
              item.location = 'before';
              item.sortIndex = 50;
              item.options.icon = '';
              item.options.text = '취소';
              item.options.hint = '취소';
              item.options.elementAttr = { class: 'btn_XS white light_filled ' };
              item.showText = 'always';
              item.options.width = '60';
              item.options.height = '30';
            }
          });

          if (this.showActionButtons?.delete) {
            toolbarItems.push({
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                hint: '삭제',
                showText: 'always',
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: async () => {
                  // 선택된 행이 없는 경우
                  if (!this.selectedRowsData?.length) {
                    this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
                    return;
                  }

                  // 삭제 확인 메시지를 무시한 경우
                  if (!(await this.$_Confirm('선택한 데이터를 삭제하시겠습니까?'))) {
                    return;
                  }

                  const payload = {
                    actionname: this.dataGrid.apiActionNm.delete,
                    data: this.selectedRowsData,
                    loading: true,
                    useErrorPopup: true,
                  };

                  const res = await store.dispatch(this.callApi, payload);
                  if (isSuccess(res)) {
                    this.$_Toast(this.$_msgContents('CMN_SUC_DELETE'), { icon: 'success' });
                    this.refreshData();
                  }
                },
              },
              location: 'before',
              sortIndex: 60,
            });
          }

          if (this.showActionButtons?.customButtons) {
            this.showActionButtons.customButtons.forEach((d, i) => {
              if (!d.sortIndex) {
                d.sortIndex = Number('7' + (i + 1));
              }
              toolbarItems.push(d);
            });
          }

          if (this.showActionButtons?.excel) {
            toolbarItems.forEach(d => {
              if (d.name === 'exportButton') {
                d.options.icon = 'export';
                d.location = 'before';
                d.sortIndex = 100;
                if (this.showActionButtons?.csv) {
                  e.format = 'csv';
                  d.options.items = d.options.items.concat([
                    {
                      icon: 'txtfile',
                      text: `csv ${this.$_msgContents('COMPONENTS.DOWNLOAD')}`,
                      onClick: () => {
                        this.onExportingCheck(e);
                      },
                    },
                  ]);
                }
              }
            });
          }
          //toolbar sort
          e.toolbarOptions.items = toolbarItems.sort((a, b) => a.sortIndex - b.sortIndex);
          if (vm.dataGrid.pager.visible) {
            toolbarItems.push({
              location: 'after',
              widget: 'dxSelectBox',
              options: {
                width: 150,
                height: 30,
                stylingMode: this.stylingMode,
                items: this.paging.pageList,
                displayExpr: 'label',
                valueExpr: 'value',
                value: this.paging.pageSize,
                onValueChanged: this.onChangePageSize,
                elementAttr: {
                  class: 'page-size-item',
                },
              },
            });
          }

          if (this.dataGrid?.hideSaveBtn) {
            //저장
            toolbarItems.forEach(d => {
              if (d.name === 'saveButton') {
                d.options.visible = false;
              }
            });
          }

          if (this.dataGrid?.hideCancelBtn) {
            //취소
            toolbarItems.forEach(d => {
              if (d.name === 'revertButton') {
                d.options.visible = false;
              }
            });
          }

          if (!this.dataGrid?.disableTotalCount) {
            //검색 결과
            toolbarItems.unshift({
              location: 'after',
              widget: 'dxTemplate',
              template: 'totalCount',
            });
          }
        }
      },
      /** @description: 그리드 선택시 변경 관련 이벤트 */
      onSelectionChanged(e) {
        if (this.checkObjKeyByCustomEvent('selectionChanged')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('selection-changed', e);
        } else {
          this.selectedRowsData = e.selectedRowsData;
        }
      },
      /** @description: 그리드 새 행 초기 셋팅 이벤트
       * ex) 필드의 순서 값 등 초기 셋팅 */
      onInitNewRow(e) {
        // implement "Clone"
        if (this.checkObjKeyByCustomEvent('initNewRow')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('init-new-row', e);
        } else if (this.isClone) {
          e.data = Object.assign({}, this.selectedCopyData);
          delete e.data.id; // remove PK property
          this.isClone = false;
        }
      },
      /** @description: 그리드 셀 관련 준비 이벤트 */
      onCellPrepared(e) {
        if (this.checkObjKeyByCustomEvent('cellPrepared')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('cell-prepared', e);
        } else if (e.rowType === 'header') {
          e.cellElement.style.textAlign = 'center';
        }
        if (e.cellElement && e.cellElement.innerHTML === '&nbsp;') {
          e.cellElement.innerHTML = '';
        }
      },
      /** @description: 그리드 행 관련 준비 이벤트 */
      onRowPrepared(e) {
        if (this.checkObjKeyByCustomEvent('rowPrepared')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-prepared', e);
        }
      },
      /** @description: 그리드 컨텐츠 준비 이벤트 */
      onContentReady(e) {
        if (this.checkObjKeyByCustomEvent('contentReady')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('content-ready', e);
        } else {
          this.paging.totalCount = e.component.totalCount();
        }
      },
      /** @description: 그리드 컨텍스트메뉴 준비 관련 이벤트 */
      onContextMenuPreparing(e) {
        let vm = this;
        if (e.target === 'content') {
          e.items = [];
          if (vm.dataGrid.showContextMenuItems?.excel) {
            e.items.push({
              text: '엑셀 다운로드',
              icon: 'dx-icon dx-icon-xlsxfile',
              onItemClick: () => {
                this.onExportingCheck(e);
              },
            });
          }
          if (vm.dataGrid.showContextMenuItems?.insert) {
            e.items.push({
              text: '행 추가',
              icon: 'dx-icon dx-icon-inserttable',
              onItemClick: () => {
                vm.isClone = true;
                vm.selectedCopyData = '';
                vm.$refs[vm.dataGrid.refName].instance.addRow();
              },
            });
          }
          if (vm.dataGrid.showContextMenuItems?.copy) {
            e.items.push({
              text: '행 복사',
              icon: 'dx-icon dx-icon-insertrowabove',
              onItemClick: () => {
                vm.isClone = true;
                vm.selectedCopyData = e.row.data;
                vm.$refs[vm.dataGrid.refName].instance.addRow();
              },
            });
          }
          if (vm.dataGrid.showContextMenuItems?.cellClipboard) {
            e.items.push({
              text: '열 클립보드',
              icon: 'dx-icon dx-icon-copy',
              onItemClick: () => {
                this.$_copyToClipboard(e.targetElement.innerText);
              },
            });
          }
          if (vm.dataGrid.showContextMenuItems?.rowClipboard) {
            e.items.push({
              text: '행 클립보드',
              icon: 'dx-icon dx-icon-copy',
              onItemClick: () => {
                let copyText = '';
                e.row.cells.forEach((d, index) => {
                  if (index === 1) copyText = d.text;
                  else copyText += ',' + d.text;
                });
                this.$_copyToClipboard(copyText);
              },
            });
          }
        }
      },
      /** @description: 옵션 변경시 이벤트 발생(단, 초기 인입시에도 호출됨)
       * @param e : 이벤트
       * */
      onOptionChanged(e) {
        if (this.checkObjKeyByCustomEvent('optionChanged')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('option-changed', e);
        } else {
          if (e.fullName === 'paging.pageSize') {
            //paging pageSize 변경시
            //페이지 목록수 옵션 캐시 저장 여부 true일 경우
            const pageSize = e.value;

            //store에 페이징의 페이지 사이즈 이력 저장
            if (pageSize > 10) {
              this.paging.pageSize = pageSize;
              //store에 페이징의 페이지 사이즈 이력 저장
              this.$_setPageSizePagingHists(pageSize);
            } else {
              //pageSize = 10 이면 이력에서 삭제플래그로 이력 삭제
              this.$_setPageSizePagingHists(pageSize, true);
            }
          } else if (e.fullName === 'paging.pageIndex') {
            //paging pageIndex 변경시
            const pageIndex = e.value;
            //store에 페이징의 페이지 인덱스 이력 저장
            if (pageIndex > 0) {
              this.paging.pageIndex = pageIndex;
              //store에 페이징의 페이지 인덱스 이력 저장
              this.$_setPageIndexPagingHists(pageIndex);
            } else {
              //pageIndex = 0 이면 이력에서 삭제플래그로 이력 삭제
              this.$_setPageIndexPagingHists(pageIndex, true);
            }
          }
        }
      },
      makeSaveHistory(event, reason = '') {
        const user = {
          userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
          userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
          deptNm: this.$store.getters.getUserInfo?.deptNm || '',
          gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
        };

        const payload = {
          actionname: 'FILE_DOWNLOAD_HISTORY_UPDATE',
          data: [
            {
              ...user,
              reason,
              fileType: 'EXCEL',
              fileNm: this.dataGrid.excel?.title,
            },
          ],
          loading: false,
        };

        event.onSaveHistory = async fileNm => {
          payload.data[0].fileNm = fileNm || this.dataGrid.excel?.title;
          return await this.CALL_API(payload);
        };
        return event;
      },
      /**
       * @description: 엑셀 다운로드 사유 입력 모달 이벤트
       */
      onDownloadReason() {
        let event = this.modal.sendData;
        const reason = this.$refs.reasonModalRef.reason;
        if (reason.trim() === '') {
          this.$_Msg(
            this.$_msgContents('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
              defaultValue: '다운로드 사유를 입력하세요.',
            }),
          );
          event.cancel = true;
        } else {
          event = this.makeSaveHistory(event, reason);
          event.cancel = false;
          this.onExporting(event);
          this.isOpenModal(false);
        }
      },
      onExportingCheck(e) {
        const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
        e = this.makeSaveHistory(e);
        if (useDownReason) {
          e.cancel = true;
          this.onOpenModal(
            'ModalDownloadReason',
            {
              title: this.$_msgContents('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
              width: '600',
              height: '400',
            },
            e,
          );
        } else {
          this.onExporting(e);
        }
      } /** @description: 엑셀 다운로드 이벤트 */,
      onExporting(e) {
        if (this.checkObjKeyByCustomEvent('exporting')) {
          //커스텀시 해당 페이지의 이벤트 호출
          // 커스텀 시 파일 다운로드 이력은 직접 구현해야함. 성공 유무를 확인할 수 없음
          // e.onSaveHistory();
          this.$emit('exporting', e);
        } else {
          //Grid Excel Export
          const title = this.dataGrid.excel.title;
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet(title);

          //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
          let columnsArr = [];
          this.dataGrid.columns.forEach(() => {
            columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 });
          });
          worksheet.columns = columnsArr;

          let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

          exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            keepColumnWidths: false,
            autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
            topLeftCell: { row: 4, column: 1 },
            customizeCell: ({ gridCell, excelCell }) => {
              if (gridCell.rowType === 'header') {
                //header 영역 설정
                excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
                excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
              } else {
                //data 영역 배경색 설정
                if (excelCell.fullAddress.row % 2 === 0) {
                  excelCell.fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'F2F2F2' },
                    bgColor: { argb: 'F2F2F2' },
                  };
                }
              }

              const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
              excelCell.border = {
                bottom: borderStyle,
                left: borderStyle,
                right: borderStyle,
                top: borderStyle,
              };
            },
          })
            .then(() => {
              const titleRow = worksheet.getRow(2);
              titleRow.height = 40;
              if (e.format === 'xlsx') {
                worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
              }
              titleRow.getCell(1).value = title;
              titleRow.getCell(1).font = { size: 22, bold: true };
              titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

              const hearderRow = worksheet.getRow(4);
              hearderRow.height = 30;
            })
            .then(() => {
              let fileName;
              if (e.format === 'csv') {
                fileName = `${title}_${today}.csv`;
                workbook.csv.writeBuffer().then(buffer => {
                  saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
                });
              } else {
                fileName = `${title}_${today}.xlsx`;
                workbook.xlsx.writeBuffer().then(buffer => {
                  saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
                });
              }
              return fileName;
            })
            .then(fileName => {
              // 다운로드 이력 저장
              e.onSaveHistory(fileName);
            });
          e.cancel = true;
        }
      },
      /** @description: 로우 클릭시 이벤트 */
      onRowClick(e) {
        // Handler of the "onRowInserting" event
        if (this.checkObjKeyByCustomEvent('rowClick')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-click', e);
        } else {
          //단일 선택 색상 설정
          setGridSingleSelection(e);

          let rowData = e.data;

          if (rowData) {
            this.focusedRowKey = rowData.id;
            this.focusedRowData = rowData;
          }
        }
      },
      /** @description: 그리드 행이 추가될 때 이벤트 */
      onRowInserting(e) {
        // Handler of the "onRowInserting" event
        if (this.checkObjKeyByCustomEvent('rowInserting')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-inserting', e);
        }
      },
      /** @description: 그리드 행이 추가된 이후 이벤트 */
      onRowInserted(e) {
        // Handler of the "onRowInserted" event
        if (this.checkObjKeyByCustomEvent('rowInserted')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-inserted', e);
        }
      },
      /** @description: 그리드 행이 수정될 때 이벤트 */
      onRowUpdating(e) {
        // Handler of the "onRowUpdating" event
        if (this.checkObjKeyByCustomEvent('rowUpdating')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-updating', e);
        }
      },
      /**
       * @description: 그리드 행이 수정될 때 이벤트
       * */
      onEditCanceled(e) {
        // Handler of the "onRowUpdating" event
        if (this.checkObjKeyByCustomEvent('editCanceled')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('edit-canceled', e);
        }
      },
      onEditingStart(e) {
        // Handler of the "onEditingStart" event
        if (this.checkObjKeyByCustomEvent('editingStart')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('editing-start', e);
        }
      },
      /** @description: 그리드 행이 수정된 이후 이벤트 */
      onRowUpdated(e) {
        // Handler of the "onRowUpdated" event
        if (this.checkObjKeyByCustomEvent('rowUpdated')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-updated', e);
        }
      },
      /** @description: 그리드 행이 삭제될 때 이벤트 */
      onRowRemoved(e) {
        // Handler of the "onRowRemoved" event
        if (this.checkObjKeyByCustomEvent('rowRemoved')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-removed', e);
        }
      },
      /** @description: 마우스 포인터가 셀에 들어오거나 나가는 후에 실행되는 이벤트  */
      onCellHoverChanged(e) {
        // Handler of the "onCellHoverChanged" event
        if (this.checkObjKeyByCustomEvent('cellHoverChanged')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('cell-hover-changed', e);
        }
      },
      /** @description: 셀이 변경 준비중일때 실행되는 이벤트 */
      onEditorPreparing(e) {
        // Handler of the "onEditorPreparing" event
        if (this.checkObjKeyByCustomEvent('editorPreparing')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('editor-preparing', e);
        }
      },
      /** @description: 편집기가 생성된 후 실행되는 함수 */
      onEditorPrepared(e) {
        // Handler of the "onEditorPrepared" event
        if (this.checkObjKeyByCustomEvent('editorPrepared')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('editor-prepared', e);
        }
      },
      /** @description: 포커스된 행이 변경되기 전에 실행되는 함수 */
      onFocusedRowChanging(e) {
        // Handler of the "onFocusedRowChanging" event
        if (this.checkObjKeyByCustomEvent('focusedRowChanging')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('focused-row-changing', e);
        }
      },
      /** @description: 포커스된 행이 변경된 후 실행되는 함수 */
      onFocusedRowChanged(e) {
        // Handler of the "onFocusedRowChanged" event
        if (this.checkObjKeyByCustomEvent('focusedRowChanged')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('focused-row-changed', e);
        }
      },
      /** @description: 그리드 초기화 이벤트 */
      onInitialized(e) {
        if (this.checkObjKeyByCustomEvent('initialized')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('initialized', e);
        }
      },
      /**
       * @description: 그리드 행 유효성 검사 이벤트
       * @param e
       */
      onRowValidating(e) {
        if (this.checkObjKeyByCustomEvent('rowValidating')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('row-validating', e);
        }
      },
      /** @description: dataGrid onSaving 이벤트 */
      async onSaving(e) {
        if (this.checkObjKeyByCustomEvent('saving')) {
          //커스텀시 해당 페이지의 이벤트 호출
          this.$emit('saving', e);
        } else {
          //저장 전 데이터 체크할 필요 있으면 checkDataBeforeSaving 사용 ex) 중복 값 있는지 체크 등...
          if (this.checkObjKeyByCustomEvent('checkDataBeforeSaving')) {
            //커스텀시 해당 페이지의 이벤트 호출
            this.$emit('checkDataBeforeSaving', e, this.saveData);
          } else {
            //저장 전 데이터 체크할 필요 없으면 바로 저장 메서드 호출
            await this.saveData(e);
          }
        }
      },
      /** @description : 데이터 저장 메서드 */
      async saveData(e) {
        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
        }

        // 변경된 값 존재 여부 체크 && 데이터 그리드 액션명 설정 여부 체크
        if (this.dataGrid.apiActionNm.update || this.dataGrid.apiActionNm.merge) {
          const isMerge = !!this.dataGrid.apiActionNm.merge;
          const gridChanges = this.processGridChanges(e, isMerge, this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id');
          const payload = {
            actionname: isMerge ? this.dataGrid.apiActionNm.merge : this.dataGrid.apiActionNm.update,
            data: gridChanges,
            useErrorPopup: true,
          };

          const res = await store.dispatch(this.callApi, payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_msgContents('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            e.component.cancelEditData();
            this.refreshData();
          } else {
            e.component.cancelEditData();
          }
        } else {
          this.$log.error('The apiActionNm is not defined.');
          this.$_Msg(this.$_msgContents('COMMON.MESSAGE.CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 그리드 조회 메서드 */
      async selectDataList() {
        const vm = this;
        const key = this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id';
        if (this.dataGrid.apiActionNm && this.dataGrid.apiActionNm.select) {
          this.dataGrid.dataSource = new CustomStore({
            key: key,
            async load(loadOptions) {
              let params = vm.$_getDxDataGridParam(loadOptions);
              if (!params.sort) {
                if (vm.dataGrid.dataSourceDefaultSortColumn) params.sort = vm.dataGrid.dataSourceDefaultSortColumn;
                else params.sort = `+${key}`;
              }

              const payload = {
                actionname: vm.dataGrid.apiActionNm.select,
                data: params,
                loading: vm.dataGrid.apiActionNm.loading ? vm.dataGrid.apiActionNm.loading : false,
                useErrorPopup: vm.dataGrid.apiActionNm.useErrorPopup ? vm.dataGrid.apiActionNm.useErrorPopup : true,
              };

              let rtnData = {
                data: [],
                totalCount: 0,
              };

              const res = await vm.$store.dispatch(vm.callApi, payload);
              if (isSuccess(res)) {
                rtnData = {
                  data: res.data.data,
                  totalCount: res.data.header.totalCount,
                };

                // 화면에 표시될 검색결과수 업데이트
                // 삭제를 하면 dxGrid에서 삭제할 id리스트에 대하여 select를 날리기 때문에
                // 실제 조회시 건수가 0이되는 버그가 있어서 예외처리함
                if (params.pagesize) vm.paging.totalCount = rtnData.totalCount;
              } else {
                vm.$log.debug('Data Loading Error');
              }
              return rtnData;
            },
            insert: values => {
              return new Promise((resolve, reject) => {
                resolve();
              });
            },
            update: (key, values) => {
              return new Promise((resolve, reject) => {
                resolve();
              });
            },
            remove: key => {
              return new Promise((resolve, reject) => {
                resolve();
              });
            },
            totalCount: loadOptions => {
              return new Promise((resolve, reject) => {
                resolve(0); // 임시로 0 반환
              });
            },
          });
        }
      },
      /** @description : 커스텀이벤트의 object key 값 체크 */
      checkObjKeyByCustomEvent(objectKey) {
        if (!this.dataGrid || typeof this.dataGrid !== 'object') {
          return false; // dataGrid 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
        }

        const customEvent = this.dataGrid.customEvent;
        if (typeof customEvent !== 'object') {
          return false; // customEvent 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
        }

        const hasObjectKey = Object.prototype.hasOwnProperty.call(customEvent, objectKey);
        return hasObjectKey ? customEvent[objectKey] : false; // objectKey가 customEvent에 존재하면 그 값을 반환하고, 그렇지 않으면 false 반환
      },
      /** @description : 그리드 refesh 메서드 */
      refreshData() {
        this.$refs[this.dataGrid.refName].instance.refresh();
      },
      /** @description : 그리드 repaint 메서드 */
      repaintData() {
        this.$refs[this.dataGrid.refName].instance.repaint();
      },
      /** @description : 페이징 관련 박스 이동 */
      movePagingBox() {
        const pager = this.$refs[this.dataGrid.refName].instance.getView('pagerView').element().dxPager('instance').element();
        const toolbarAfterItem = this.$refs[this.dataGrid.refName].instance.getView('headerPanel')._toolbar._$afterSection[0];

        const toolbarItem = document.createElement('div');
        toolbarItem.classList.add('dx-item');
        toolbarItem.classList.add('dx-toolbar-item');
        toolbarItem.classList.add('dx-toolbar-button');
        const toolbarItemContent = document.createElement('div');
        toolbarItemContent.classList.add('dx-item-content');
        toolbarItemContent.classList.add('dx-toolbar-item-content');
        toolbarItemContent.appendChild(pager);
        toolbarItem.appendChild(toolbarItemContent);
        toolbarAfterItem.appendChild(toolbarItem);
      },
      /**@description : DataGrid clearSelection 메서드 */
      clearSelection() {
        this.$refs[this.dataGrid.refName].instance.clearSelection();
      },
      /**@description : DataGrid clearFilter 메서드 */
      clearFilter() {
        this.$refs[this.dataGrid.refName].instance.clearFilter();
      },
      /**@description : DataGrid clearSorting 메서드 */
      clearSorting() {
        this.$refs[this.dataGrid.refName].instance.clearSorting();
      },
      /**
       * 그리드 Height 계산
       *
       * @param propHeight
       * @return {*|string}
       */
      calculateHeight(propHeight) {
        if (propHeight === null || propHeight === undefined) {
          let height = this.topElement('#dxDataGrid') + this.heightElement('.dx-datagrid-header-panel');
          return 'calc(100vh - ' + height + 'px)';
        }
        return propHeight;
      },
      topElement(e) {
        const divElement = document.querySelector(e);
        const rect = divElement.getBoundingClientRect();
        return rect.top;
      },
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },
      /**
       * 사용자 prop 설정
       */
      setData() {
        if (this.dataGrid.showActionButtons) {
          Object.keys(this.showActionButtons).forEach(key => {
            if (Object.prototype.hasOwnProperty.call(this.dataGrid.showActionButtons, key)) {
              this.showActionButtons[key] = this.dataGrid.showActionButtons[key];
            }
          });
        }
      },
      /**
       * 그리드 변경된 데이터 준비
       *
       * @param e saving 이벤트 객체
       * @param isMerge 병합 여부 (기본값 true)
       * @param keyExpr key 값 (기본값 'id')
       * @return {*[]} 변경된 데이터
       */
      processGridChanges(e, isMerge = true, keyExpr = 'id') {
        const changes = [];

        e.changes.forEach(d => {
          let dataKey = d.key;
          let dataMap = d.data;

          if (d.type === 'update' && isMerge) {
            e.component
              .byKey(dataKey)
              .then(data => {
                dataMap = Object.assign(data, d.data);
              })
              .catch(error => {
                console.error(error);
              });
          } else {
            dataKey = null; // 신규일 경우 key 값이 null 이어야 함
          }

          dataMap[keyExpr] = dataKey;
          changes.push(dataMap);
        });
        return changes;
      },
      /**
       * 그리드 변경된 데이터 준비 (작업이력 저장데이터 포함) <br>
       * 해당 함수는 병합 데이터를 기본적으로 만들도록 설정되어 있음
       *
       * @param e saving 이벤트 객체
       * @param originalData 원본 데이터 (기본값 {})
       * @param keyExpr key 값 (기본값 'id')
       * @return {{data: *[], workLog: {content: {}, preContent: {}}}} 변경된 데이터
       */
      processGridChangesWithWorkLog(e, originalData = {}, keyExpr = 'id') {
        const changes = {
          data: [],
          workLog: { content: {}, preContent: {} },
        };

        // https://js.devexpress.com/Vue/Documentation/ApiReference/Common_Types/grids/#DataChangeType 참고
        e.changes.forEach((change, index) => {
          let dataKey = change.key;
          let dataMap = change.data;

          if (change.type === 'update') {
            // 변경 전 데이터 확인
            const oldData = originalData[dataKey];
            dataMap = Object.assign({}, oldData, change.data);

            // 변경 전후의 내용을 기록
            changes.workLog.preContent[index] = oldData;
            changes.workLog.content[index] = dataMap;
          } else if (change.type === 'insert') {
            // 신규 생성 타입
            dataKey = null; // 신규 생성 시 key 값이 없음
            changes.workLog.preContent[index] = {};
            changes.workLog.content[index] = dataMap;
          } else if (change.type === 'remove') {
            // 삭제 타입
            const oldData = originalData[dataKey];
            if (oldData) {
              changes.workLog.preContent[index] = oldData;
              changes.workLog.content[index] = {};
            }
          }

          dataMap[keyExpr] = dataKey;
          changes.data.push(dataMap);
        });

        return changes;
      },
    },
    async created() {
      this.setData(); // 사용자 prop 설정

      //페이지 목록수 캐시 저장 여부 설정
      const pageSizeCacheFl = this.$_getSystemData('page_size_cache_fl') ? this.$_getSystemData('page_size_cache_fl').configValue : null;
      this.paging.pageSizeCacheFl = isTrue(pageSizeCacheFl);

      await this.bindPagingData(); //페이징 관련 데이터 바인딩
      if (this.dataGrid.apiActionNm && this.dataGrid.apiActionNm.select) {
        await this.selectDataList();
      }
    },
    mounted() {
      this.grid.height = this.calculateHeight(this.dataGrid.height); // 그리드 높이 설정

      if (this.dataGrid.pager.visible) {
        this.movePagingBox();
      }
    },
  };
</script>

<style>
  /* 검색 결과 */
  .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-label .total-count-item {
    font-size: 13px;
    font-weight: normal;
    color: #545454;
  }

  /* page size selectbox */
  .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-button .page-size-item {
    margin-left: 30px;
  }
</style>
