<!--
  PACKAGE_NAME : src\pages\esp\user\login.vue
  FILE_NAME : login
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 유저 로그인
-->
<template>
  <div ref="amorWrapper" class="amor_wrapper">
    <div id="viewport" class="viewport">
      <div class="visual-left">
        <img
          v-if="!bannerLoading && getBannerImg"
          :src="getBannerImg"
          @error="setBannerErrorHandled"
          class="visual-bg"
          alt="login banner image"
        />
        <div class="visual-footer" :style="{ color: this.cpRightFontColor }">{{ cpRightText }}</div>
      </div>
      <div class="login-wrap">
        <div ref="loginGroup" class="login-group">
          <div ref="loginBoard" class="login-board fade-in-up">
            <header class="login-header fade-in-up">
              <h1 class="login-text">{{ msgContents('COMMON.WORD.LOGIN', { defaultValue: '로그인' }) }}</h1>
            </header>
            <div class="login-cont fade-in-up">
              <form class="login-form">
                <div class="input id id-box fade-in-up">
                  <label for="ecsloginId">{{ msgContents('COMMON.WORD.LOGIN_ID', { defaultValue: '아이디' }) }}</label>
                  <input
                    type="text"
                    :class="errMsg.loginId ? 'on' : ''"
                    :placeholder="msgContents('COMMON.WORD.LOGIN_ID', { defaultValue: '아이디' })"
                    value=""
                    autocomplete="inputLoginId"
                    v-model="loginId"
                    @keyup="onIdKeyup"
                    ref="inputLoginId"
                    :readonly="!isIdFocused"
                    @focus="isIdFocused = true"
                    @blur="isIdFocused = false"
                  />
                  <i class="login-bak-id"></i>
                </div>
                <div class="login-password-infobox on fade-in-up">
                  <div :class="errMsg.loginId ? 'id-info-txt on' : 'id-info-txt'">{{ errMsg.loginId }}</div>
                </div>
                <div class="input pwd pwd-box fade-in-up">
                  <label for="ecsloginPwd">{{ msgContents('COMMON.WORD.PASSWORD', { defaultValue: '비밀번호' }) }}</label>
                  <input
                    :class="errMsg.password ? 'on' : ''"
                    :type="showPassword ? 'text' : 'password'"
                    name="password"
                    value=""
                    :placeholder="msgContents('COMMON.WORD.PASSWORD', { defaultValue: '비밀번호' })"
                    ref="inputPassword"
                    autocomplete="inputPassword"
                    @keyup="onPwdKeyup"
                    v-model="password"
                    :readonly="!isPwdFocused"
                    @focus="isPwdFocused = true"
                    @blur="isPwdFocused = false"
                  />
                  <i class="login-bak-pw"></i>
                  <button
                      v-show="password.length"
                      type="button"
                      class="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer w-9 h-9"
                      @click="handlePasswordToggleClick"
                  >
                    <img v-if="showPassword"
                         class="inline-block" src="@/assets/images/eye.png" alt="비밀번호 표시"
                    />
                    <img v-else
                         class="inline-block" src="@/assets/images/eye-off.png" alt="비밀번호 숨기기"
                    />
                  </button>
                </div>
                <div class="login-password-infobox fade-in-up">
                  <div :class="errMsg.password ? 'pwd-info-txt on' : 'pwd-info-txt'">{{ errMsg.password }}</div>
                </div>

                <!-- FIXME : 로그인 유지 체크박스 필요, isKeepLogin -->
                <div class="check-type ck-box fade-in-up">
                  <input type="checkbox" id="saveId" name="save-id" v-model="isSaveId" />
                  <label for="saveId">{{ msgContents('COMMON.WORD.SAVE_ID', { defaultValue: '아이디 저장' }) }}</label>
                </div>

                <div class="pw-admin-btn-box fade-in-up">
                  <button type="button" class="btn-login" @click="loginProcess">
                    {{ msgContents('COMPONENTS.LOGIN', { defaultValue: '로그인' }) }}
                  </button>
                </div>
              </form>
            </div>

            <footer class="login-footer fade-in-up">
              <p>
                {{ msgContents('COMMON.MESSAGE.FORGOT_PASSWORD', { defaultValue: '패스워드를 분실한 경우에는 관리자에게 문의하세요.' }) }}
              </p>
              <div ref="loginFailTxt" class="login-fail-txt">
                <p>
                  <strong>{{ errMsg.form }}</strong>
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { isSuccess, encryptPassword } from '@/plugins/common-lib';

  export default {
    watch: {
      'errMsg.form': function (newVal) {
        this.$refs.loginFailTxt.classList.toggle('on', newVal);
      },
      '$route.query.routerError': {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.errMsg.form = newVal;

            // 쿼리 문자열에서 routerError를 제거
            const newQuery = { ...this.$route.query };
            delete newQuery.routerError;

            this.$router.replace({ query: newQuery }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                console.error(err);
              }
            });
          }
        },
      },
    },
    data() {
      return {
        errorMessages: {
          loginId: this.$_msgContents('COMMON.MESSAGE.ENTER_ID', { defaultValue: '아이디를 입력해 주세요.' }),
          password: this.$_msgContents('COMMON.MESSAGE.ENTER_PASSWORD', { defaultValue: '비밀번호를 입력해 주세요.' }),
        },
        isIdFocused: false,
        isPwdFocused: false,
        valid: true,
        showPassword: false,
        isSaveId: false,
        loginId: '',
        password: '',
        redirectUrl: null,
        errMsg: {
          loginId: null,
          password: null,
          form: null,
        },
        motion: null,
        topText: '',
        topFontSize: '32px',
        topFontColor: '#fff',
        btmText: '',
        btmFontSize: '16px',
        btmFontColor: '#fff',
        topTextLoca: '50%',
        leftTextLoca: '50%',
        cpRightText: 'ⓒ 2023. ECS Telecom all right reserved',
        cpRightFontColor: '#fff',
        bannerLoading: true, // 배너 이미지 로딩 여부
        bannerImage: null, // 배너 이미지
        bannerErrorHandled: false, // 배너 이미지 에러 핸들링 여부
      };
    },
    computed: {
      /**
       * 배너 이미지 가져오기
       * @return {string|string|string}
       */
      getBannerImg() {
        // FIXME : 배너 이미지는 스토어 초기화 이전에 호출되므로 초기데이터 호출을 하는 API 별도 구성 필요.
        const bannerImg = this.bannerImage ? this.bannerImage : this.$store.getters.getThemeSetting?.bannerImg;
        return bannerImg ? this.$_getAttachFileURL(null, bannerImg) : '@/assets/images/login/mask_group.png';
      },
    },
    methods: {
      msgContents(messageKey, options) {
        return this.$_msgContents(messageKey, options);
      },
      onIdKeyup(e) {
        this.handleKeyup(e, 'loginId', this.$refs.inputPassword.focus.bind(this.$refs.inputPassword));
      },
      onPwdKeyup(e) {
        this.handleKeyup(e, 'password', this.loginProcess.bind(this));
      },
      handleKeyup(e, field, callback) {
        if (this[field].trim() === '') {
          this.errMsg[field] = this.errorMessages[field];
        } else {
          this.errMsg[field] = null;
          if (e.key === 'Enter') {
            callback();
          }
        }
      },
      /**
       * 입력값 유효성 체크
       *
       * @param field
       * @return {boolean}
       */
      validateInput(field) {
        if (!this[field] || this[field].trim() === '') {
          this[field] = '';
          this.errMsg[field] = this.errorMessages[field];
          field === 'loginId' ? this.$refs.inputLoginId.focus() : this.$refs.inputPassword.focus();
          return false;
        }
        return true;
      },
      /**
       * 로그인 Payload 생성
       * @param loginId
       * @param password
       * @return {{params: {keeploginfl: boolean, loginid, loginpwd: string, continue: string | (string | null)[]}}}
       */
      createLoginPayload(loginId, password) {
        return {
          loginId: loginId,
          loginPwd: encryptPassword(loginId, password, this.$store.getters.getEncryptionType),
          keepLoginFl: this.isKeepLogin,
        };
      },
      /**
       * 로그인 에러 처리
       * @param error
       */
      handleLoginError(error) {
        this.$log.debug('handleLoginError', error);
        this.errMsg.form = 'API 서버 오류, 관리자에게 문의해 주세요.';
      },
      /**
       * 로그인 처리
       * @return {Promise<void>}
       */
      async loginProcess() {
        const loginId = this.loginId.trim();
        const password = this.password.trim();

        if (!this.validateInput('loginId') || !this.validateInput('password')) {
          return;
        }

        if (this.isSaveId) {
          localStorage.setItem('loginId', loginId);
        } else {
          localStorage.removeItem('loginId');
        }

        this.errMsg.form = null;
        const payload = this.createLoginPayload(loginId, password);

        try {
          const res = await this.$store.dispatch('LOGIN', payload);
          if (isSuccess(res)) {
            const loginResult = res.data.data;
            await this.$router.push({ path: loginResult.mainPageUrl });
          } else {
            this.errMsg.form = res.data.header.resMsg;
          }
        } catch (error) {
          this.handleLoginError(error);
          console.error('Login process error:', error);
        }
      },
      /**
       * 배너 이미지 에러 핸들러
       * @param e
       */
      setBannerErrorHandled(e) {
        if (!this.bannerErrorHandled) {
          e.target.src = require('@/assets/images/login/mask_group.png');
          this.bannerErrorHandled = true;
        }
      },
      /** 비밀번호 보이기 토글  */
      handlePasswordToggleClick() {
        this.showPassword = !this.showPassword
      }
    },
    async mounted() {
      this.loginId = this.$store.getters?.getLoginId ? this.$store.getters.getLoginId : '';
      this.isSaveId = !!this.loginId;

      // 로그인 페이지 진입 시 스토어 초기화
      this.$store.commit('resetSessionState');
      this.$store.commit('resetState');

      this.$refs.amorWrapper.classList.add('loaded');
      this.$refs.amorWrapper.parentNode.style.height = '100%';

      await this.$store.dispatch('INIT_THEME_SETTING');

      const uiThemeData = this.$store.getters.getThemeSetting;
      if (uiThemeData) {
        this.bannerImage = uiThemeData.bannerImg;
        this.topText = uiThemeData.topText;
        this.topFontSize = uiThemeData.topFontSize + 'px';
        this.topFontColor = uiThemeData.topFontColor;
        this.btmText = uiThemeData.btmText;
        this.btmFontSize = uiThemeData.btmFontSize + 'px';
        this.btmFontColor = uiThemeData.btmFontColor;
        this.topTextLoca = uiThemeData.topTextLoca + '%';
        this.leftTextLoca = uiThemeData.leftTextLoca + '%';
        this.cpRightText = uiThemeData.cpRightText;
        this.cpRightFontColor = uiThemeData.cpRightFontColor;
      }

      // 배너 이미지 오류 처리
      if (!this.bannerImage) {
        this.bannerLoading = '@/assets/images/login/mask_group.png';
      }
      this.bannerLoading = false;

      // 아이디 저장 체크박스 여부에 따라 포커스 이동
      if (this.isSaveId) {
        this.$refs.inputPassword.focus();
      } else {
        this.$refs.inputLoginId.focus();
      }
    },
  };
</script>
<style scoped src="@/assets/css/login.css"></style>
