import { EventBus } from '@/event-bus';
import { ucApi } from '@/api';
import { getRoutingInfo } from './esp';
import { errorPopup, responseConsoleLogger } from '../../api/error-handle';

/**
 * EUC API 호출
 * @param state
 * @param payload
 * @return {Promise<*|AxiosResponse<T>|AxiosInterceptorManager<AxiosResponse>|e.Response<any, Record<string, any>>|AuthenticatorResponse>}
 * @constructor
 */
export const CALL_EUC_API = async ({ state }, payload) => {
  let res;
  try {
    const routingInfo = getRoutingInfo({ state }, payload);
    let requestPath = routingInfo.path;
    if (payload?.path) {
      if (payload.path.startsWith('/')) {
        requestPath += payload.path;
      } else {
        requestPath += '/' + payload.path;
      }
    }

    // API 호출 시 필요한 정보
    const requestParam = {
      host: routingInfo.host,
      path: requestPath,
      timeout: payload.timeout || routingInfo.timeout,
      method: routingInfo.method,
      actionname: payload.actionname,
      data: payload.data,
      responseType: payload.responseType,
      headers: payload.headers,
    };

    payload.loading && EventBus.$emit('app:progress', true);

    res = await ucApi.callApi(requestParam);

    // 디버그 로거
    responseConsoleLogger(payload.actionname, res);

    payload.loading && EventBus.$emit('app:progress', false);
    return res;
  } catch (error) {
    payload.loading && EventBus.$emit('app:progress', false);

    // 에러 팝업 처리
    errorPopup(error, payload);
    return error.response || error;
  }
};
