<!--
  PACKAGE_NAME : src\pages\esp\system\alarm\drop-down-panel.vue
  FILE_NAME : drop-down-panel
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 알림 드롭다운 패널
-->
<template>
  <transition name="slide">
    <div id="alarm-wrap" class="alim-wrap" v-if="alarmInfo.isOpen">
      <div class="alim-header">
        <div class="alim-header-title" v-html="templateTitle()"></div>
        <button
          type="button"
          class="btn-icon close"
          @click="closeAlarm"
          style="position: absolute; top: calc(50% - 10px); right: 10px"
        ></button>
      </div>
      <div class="tabs ecs-mini-tab">
        <ul v-if="alarmInfo.data.typeList.length > 0" class="ecs-tab-box">
          <li
            v-for="(typeInfo, index) in alarmInfo.data.typeList"
            :key="index"
            class="tb_tp"
            :class="{ on: activeTabIndex === index }"
            @click="handleTabClick(index)"
          >
            {{ typeInfo.name }}<i class="badge">{{ typeInfo.count }}</i>
          </li>
        </ul>
      </div>
      <div class="alim-cont">
        <div class="scroll-wrapper scrollbar-dynamic" style="position: relative">
          <div
            class="scrollbar-dynamic scroll-content scroll-scrolly_visible alarm-scroll"
            style="height: auto; margin-bottom: 0px; margin-right: 0px; max-height: 350px; overflow-y: auto !important"
          >
            <ul v-if="alarmInfo.data.filterList.length === 0" style="height: 350px">
              <li class="nothing">
                <p>알림이 없습니다.</p>
              </li>
            </ul>
            <ul v-else style="height: 350px">
              <!-- list looping start -->
              <transition-group name="list">
                <li v-for="event in alarmInfo.data.filterList" :key="event.id">
                  <div class="badge-box"><i class="new-badge" v-if="isNewEvent(event)"></i></div>
                  <span :class="getDisplayGbClass(event)">{{ getDisplayGbName(event) }}</span>
                  <div
                    class="alim-list-box"
                    :class="{ hover: isClickAbleAlarm(event) }"
                    @click="handleClickAlarm(event)"
                    v-html="templateAlarmContent(event)"
                    ref="alarmContent"
                  ></div>
                  <button type="button" class="alim-del" @click="handleDeleteAlarm(event.id)">
                    <span class="hidden"></span>
                  </button>
                </li>
                <!--// list looping start -->
              </transition-group>
            </ul>
          </div>
        </div>
        <div class="all-del-btn">
          <button v-if="alarmInfo.data.filterList.length > 0" @click="handleDeleteAlarm('all')">전체삭제</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { downloadFile, isSuccess } from '@/plugins/common-lib';
  import moment from 'moment';

  export default {
    components: {},
    watch: {},
    data() {
      return {
        default: () => ({
          autoDelete: 15,
          tabType: [], //
          displayCd: [],
          displayCode: ['SELF', 'ALL', 'ID', 'ROLE'],
          actionType: ['GOTO_MENU', 'DOWNLOAD_LINK', 'MSG_MODAL', 'PROGRESS_BAR', 'REF_FUNC', 'DOWNLOAD_REPORT_EXCEL'],
        }),
        activeTabIndex: 0,
        alarmInfo: {
          isOpen: false,
          title: '',
          total: 0,
          data: {
            typeList: [],
            alarmList: [],
            filterList: [],
          },
          timer: 1500,
        },
        queue: {
          history: [],
        },
      };
    },
    computed: {},
    methods: {
      isNewEvent(event) {
        // 읽음 여부 확인
        return !event.checkedDt || event.checkedDt < event.publishDt;
      },

      isClickAbleAlarm(event) {
        if (!event) {
          return false;
        }
        return event.actionList?.filter(action => action.actionType === 'GOTO_MENU'
          || action.actionType === 'REF_FUNC') || false;
      },

      getDisplayGbName(event) {
        // displayCd
        return this.default.displayCd.find(type => type.id === event.displayCd)?.codeNm ?? '기본';
      },

      getDisplayGbClass(event) {
        const displayCdNm = this.getDisplayGbName(event);
        switch (displayCdNm) {
          case '작업':
            return 'alim-work';
          case '공지':
            return 'alim-notice';
          case '서버':
            return 'alim-server';
          default:
            return 'alim-notice';
        }
      },

      getMenuInfoById(menuId) {
        // 메뉴 정보 조회
        return this.$store.getters.getMenuList.find(d => d.id === menuId) ?? {};
      },

      updateAlarmList() {
        // history 에 있는거 업데이트
        if (this.queue.history.length > 0) {
          const alarmList = this.alarmInfo.data.alarmList;

          this.queue.history.forEach(event => {
            const existingIndex = alarmList.findIndex(history => history.updateKey === event.updateKey);

            if (existingIndex > -1 && Number(alarmList[existingIndex]?.publishDt) < Number(event.publishDt)) {
              // 있는데 publishDt 가 더 크면 교체
              alarmList.splice(existingIndex, 1, event);
            } else if (existingIndex === -1) {
              // 없으면 넣고
              alarmList.unshift(event);
            }
          });
        }
        // 끝나면 queue 초기화
        this.queue.history = [];

        // 순서대로 정렬
        this.alarmInfo.data.alarmList = this.alarmInfo.data.alarmList
          .filter(event => event.deleteSt !== 'Y')
          .sort((a, b) => Number(b.publishDt) - Number(a.publishDt));
      },

      updateTabCount() {
        const vm = this;
        vm.alarmInfo.data.typeList = vm.default.tabType.map(type => ({
          name: type,
          count: vm.alarmInfo.data.alarmList.reduce((count, event) => {
            const displayCdName = vm.getDisplayGbName(event);
            return count + (type === 'ALL' || displayCdName === type ? 1 : 0);
          }, 0),
        }));
      },

      updateFilterList() {
        // filterList
        const vm = this;
        const activeTab = this.alarmInfo.data.typeList[this.activeTabIndex];
        this.alarmInfo.data.filterList = this.alarmInfo.data.alarmList.filter(event => {
          if (activeTab.name === 'ALL') {
            return true;
          } else {
            return vm.getDisplayGbName(event) === activeTab.name;
          }
        });
      },

      updateDateCheckedAlarm(ids) {
        const vm = this;
        vm.alarmInfo.data.alarmList.forEach(event => {
          if (ids.includes(event.id)) {
            event.checkedDt = moment().format('YYYYMMDDHHmmssSSS');
          }
        });
      },

      templateTitle() {
        return `<span>알림</span> (${this.default.autoDelete ?? 15}일 후 자동삭제)`;
      },

      templateAlarmTitle(alarm) {
        const maxLength = 100; // 최대 길이
        // subContent 는 여기서만씀.
        alarm.subContent = alarm.message.substring(0, Math.min(maxLength, alarm.message.length));
        if (alarm.subContent.length === maxLength) {
          alarm.subContent += '...';
        }

        return `[${this.getDisplayGbName(alarm)}] ${alarm.subContent}`;
      },

      templateAlarmContent(alarm) {
        return `
				${this.templateAlarmMain(alarm)}
				<span>${this.templateDate(alarm.publishDt)}</span>
			`;
      },

      templateDate(date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },

      templateAlarmMain(alarm) {
        // default 셋팅
        const vm = this;
        let defaultTemplate = `${alarm.message}`;

        // event의 actionList 에 있는값으로 셋팅한다.
        const actionList = alarm.actionList;
        let preFixTemplate = '';
        let suffixTemplate = '';

        if (actionList?.length > 0) {
          actionList.forEach(action => {
            // 메뉴이동시
            if (action.actionType === 'GOTO_MENU') {
              const menuInfo = vm.getMenuInfoById(action.actionContent ?? -1);
              if (menuInfo) {
                preFixTemplate = `[${menuInfo.menuNm}]`;
              }
            }
            // 프로그레스바
            if (action.actionType === 'PROGRESS_BAR') {
              suffixTemplate = `
							<div class="alim-bar-box">
								<div class="bar-per-box"><div class="bar-per" style="width: ${Number(action.actionContent)}%"></div></div>
								${
                  Number(action.actionContent) === 100 && actionList.find(action => action.actionType === 'DOWNLOAD_REPORT_EXCEL') != null
                    ? `<span class="report-download" data-id="${Number(
                        actionList.find(action => action.actionType === 'DOWNLOAD_REPORT_EXCEL')?.actionContent ?? 0,
                      )}">다운로드</span>`
                    : `<span>${Number(action.actionContent)}%</span>`
                }
							</div>
						`;
            }
          });

          defaultTemplate = `${preFixTemplate.trim()} ${defaultTemplate.trim()}`;

          return `<p>${defaultTemplate.trim()}</p>${suffixTemplate.trim()}`;
        }

        return `<p>${defaultTemplate.trim()}</p>`;
      },

      toggleAlarm(flag) {
        if (flag) {
          this.openAlarm();
        } else {
          this.closeAlarm();
        }
      },

      openAlarm() {
        this.alarmInfo.isOpen = true;
        // 데이터 세팅 ( title, queue에 있는거 가져오고, tabCount, fitler설정)
        this.setInitDataWhenOpen();
      },

      closeAlarm() {
        // checked 0 으로 셋팅해야함
        this.sendViewCheckAlarm();
        this.alarmInfo.isOpen = false;
        this.$emit('AlarmUnCheckedList', []);
        this.clearAlarmData();
      },

      setInitDataWhenOpen() {
        this.updateAlarmList();
        this.updateTabCount();
        this.updateFilterList();
      },

      clearAlarmData() {
        this.alarmInfo.data.typeList = [];
        this.alarmInfo.data.filterList = [];
        this.queue.history = [];
      },

      //
      handleInsertNewAlarm(alarm) {
        this.queue.history.unshift(alarm);
        if (this.alarmInfo.isOpen) {
          this.setInitDataWhenOpen();
        }
      },

      handleTabClick(index) {
        this.activeTabIndex = index;
        this.updateFilterList();
      },

      async handleDeleteAlarm(id = -1) {
        let ids = [];
        if (id === 'all') {
          // 전체 삭제
          const activeTabName = this.alarmInfo.data.typeList[this.activeTabIndex].name ?? '';
          if (await this.$_Confirm(`${activeTabName !== 'ALL' ? activeTabName : '전체'} 알림을 모두 삭제하시겠습니까?`)) {
            ids = this.alarmInfo.data.filterList.map(event => event.id);
          }
        } else if (id > 0) {
          ids.push(id);
        }

        if (ids.length > 0) {
          const payload = {
            actionname: 'ALARM_DELETE',
            data: { ids: ids },
            loading: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            // 삭제 성공
            this.alarmInfo.data.alarmList = this.alarmInfo.data.alarmList.filter(event => {
              return !ids.includes(event.id);
            });

            if (this.alarmInfo.isOpen) {
              this.updateTabCount();
              this.updateFilterList();
            }

            this.$_Toast('삭제가 완료되었습니다.', { position: 'bottom-end', timer: this.alarmInfo.timer, icon: 'success' });
          } else {
            this.$_Toast('알림을 삭제하는 데 실패하였습니다.', {
              position: 'bottom-end',
              timer: this.alarmInfo.timer,
              icon: 'error',
            });
          }
        }
      },

      handleClickAlarm(event) {
        if (event.actionList?.length > 0) {
          for (const action of event.actionList) {
            if (action.actionType === 'GOTO_MENU') {
              const menuInfo = this.getMenuInfoById(action.actionContent ?? -1);
              this.$router.push({ path: menuInfo.pageUrl });
              this.closeAlarm();
              return;
            }

            if (action.actionType === 'REF_FUNC') {
              this.executeRefFunc(action.actionContent);
              return;
            }
          }
        }
      },

      executeRefFunc(fmtStr) {
        let [refName, methodName] = fmtStr.split('@');

        let ref = this.getRefVue(refName, this.$root.$children[0].$children); // Root -> App 에서 출발함.
        if (ref && typeof ref[methodName] === 'function') {
          this.closeAlarm();
          ref[methodName]();
        }
      },

      getRefVue(name, components) {
        if (!components || components.length === 0) {
          return null;
        }

        const [currentName, ...restNames] = name.split('.');
        const foundComponent = components.find(component => (component.$options?.name ?? '') === currentName);

        if (!foundComponent) {
          return null;
        }

        if (restNames.length === 0) {
          return foundComponent;
        }

        return this.getRefVue(restNames.join('.'), foundComponent.$children);
      },

      sendViewCheckAlarm() {
        const ids = this.alarmInfo.data.alarmList.filter(alarm => this.isNewEvent(alarm)).map(alarm => alarm.id);
        if (ids.length > 0) {
          this.$emit('UpdateAlarmChecked', { ids });
          this.updateDateCheckedAlarm(ids);
        }
      },

      async initAlarm() {
        // 초기 한번만 데이터 가져오기 ( 탭정보, 다른코드 정보, 맴버정보)
        this.default.autoDelete = this.$_getSystemData('alarm_auto_delete')?.configValue ?? 15;
        this.default.displayCd = this.$_getCode('alarm_display_type');
        this.default.tabType = this.default.displayCd.map(code => code.codeNm).reduce((acc, value) => acc.concat([value]), ['ALL']);

        const payload = {
          actionname: 'ALARM_LIST',
          data: { receiverId: this.$store.getters.getLoginId ?? 'default' },
          loading: true,
        };
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          const alarmList = res?.data?.data ?? [];
          const newAlarmList = alarmList?.filter(event => this.isNewEvent(event)) ?? [];

          if (newAlarmList.length > 0) {
            this.$_Toast('새로운 알림 메세지를 확인해주세요', { position: 'bottom-end', timer: this.alarmInfo.timer, icon: 'info' });
            this.$emit(
              'AlarmUnCheckedList',
              newAlarmList.filter(alarm => alarm.updateKey),
            );
          }
          this.queue.history = [...alarmList];
        }
      },
      async onCheckExcelDownload(id) {
        const payload = {
          actionname: 'REPORT_HISTORY_UPDATE_CNT',
          data: {
            param: { id },
          },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          await this.onDownLoadExcelFile(id);
        } else {
          this.$_Msg(`다운로드 실패 \n ${res.data?.header?.resMsg ? `Cause :  ${res.data.header.resMsg}` : ''}`);
        }
      },

      async onDownLoadExcelFile(id) {
        let payload = {
          data: {
            param: { id },
          },
          actionname: 'REPORT_DOWNLOAD_EXCEL',
          loading: true,
          responseType: 'arraybuffer',
          onDownloadProgress: progressEvent => {
            this.downloadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          },
        };

        const res = await this.CALL_API(payload);

        try {
          await downloadFile(res);
        } catch {
          this.$_Msg(`다운로드 실패 \n ${res.data?.header?.resMsg ? `Cause :  ${res.data.header.resMsg}` : ''}`);
        }
      },

      // report DownLoad 이벤트
      addReportDownloadEvent() {
        const that = this;
        const clickEvent = e => {
          e.preventDefault();
          e.stopPropagation();
          that.onCheckExcelDownload(e.target?.dataset?.id ?? 0);
        };

        this.$refs['alarmContent']?.forEach(element => {
          const target = element.querySelector('.report-download');
          target?.removeEventListener('click', clickEvent);
          target?.addEventListener('click', clickEvent);
        });
      },
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
      this.initAlarm();
    },
    beforeUpdate() {},
    updated() {
      this.addReportDownloadEvent();
    },
    beforeDestroy() {},
    destroyed() {},
  };
</script>

<style scoped>
  .list-enter-active,
  .list-leave-active {
    transition: all 0.5s ease;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }

  .slide-enter-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    -o-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to,
  .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter,
  .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .hover:hover {
    cursor: pointer;
  }

  /* 아래의 모든 코드는 영역::코드로 사용 */

  .alarm-scroll::-webkit-scrollbar {
    width: 6px !important;
    /* 스크롤바의 너비 */
  }

  .alarm-scroll::-webkit-scrollbar-thumb {
    min-height: 24px;
    height: 30% !important;
    /* 스크롤바의 길이 */
    background: #6c6e71 !important;
    /* 스크롤바의 색상 */

    border-radius: 6px !important;
  }

  .alarm-scroll::-webkit-scrollbar-track {
    background: rgba(33, 122, 244, 0.1) !important;
    /*스크롤바 뒷 배경 색상*/
  }
</style>
