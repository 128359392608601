import Login from '@/pages/esp/user/login.vue';
import PasswordChange from '@/pages/esp/user/password-change.vue';

export default [
  {
    path: '/login',
    name: 'Login',
    components: { singlePage: Login },
    meta: { requiresAuth: false, noTrack: true },
  },
  {
    path: '/password-change',
    name: 'PasswordChange',
    components: { singlePage: PasswordChange },
    meta: { requiresAuth: true, noTrack: true },
  },
];
