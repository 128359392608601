<template>
  <aside>
    <!-- 모바일 -->
    <div class="menu red" id="myMenu" v-if="$store.getters.getIsMobile">
      <div class="side-top">
        <button type="button" data-menu="" class="on" @click="closeMenu">
          <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
            <path
              id="lnb_icon1"
              d="M19.1667 16.7708C19.5359 16.771 19.8908 16.9132 20.158 17.1679C20.4252 17.4227 20.5841 17.7705 20.6018 18.1392C20.6196 18.508 20.4948 18.8694 20.2533 19.1486C20.0118 19.4278 19.6721 19.6035 19.3047 19.6391L19.1667 19.6458H3.83337C3.46421 19.6456 3.10926 19.5034 2.84209 19.2486C2.57491 18.9939 2.41599 18.6461 2.39825 18.2774C2.38051 17.9086 2.50531 17.5472 2.74679 17.268C2.98828 16.9887 3.32793 16.8131 3.69537 16.7775L3.83337 16.7708H19.1667ZM19.1667 10.0625C19.548 10.0625 19.9136 10.2139 20.1832 10.4835C20.4528 10.7531 20.6042 11.1187 20.6042 11.5C20.6042 11.8812 20.4528 12.2468 20.1832 12.5164C19.9136 12.786 19.548 12.9375 19.1667 12.9375H3.83337C3.45213 12.9375 3.08649 12.786 2.81691 12.5164C2.54732 12.2468 2.39587 11.8812 2.39587 11.5C2.39587 11.1187 2.54732 10.7531 2.81691 10.4835C3.08649 10.2139 3.45213 10.0625 3.83337 10.0625H19.1667ZM19.1667 3.35413C19.548 3.35413 19.9136 3.50558 20.1832 3.77516C20.4528 4.04474 20.6042 4.41038 20.6042 4.79163C20.6042 5.17287 20.4528 5.53851 20.1832 5.80809C19.9136 6.07768 19.548 6.22913 19.1667 6.22913H3.83337C3.45213 6.22913 3.08649 6.07768 2.81691 5.80809C2.54732 5.53851 2.39587 5.17287 2.39587 4.79163C2.39587 4.41038 2.54732 4.04474 2.81691 3.77516C3.08649 3.50558 3.45213 3.35413 3.83337 3.35413H19.1667Z"
              fill="#5F6368"
            />
          </svg>
        </button>

        <!-- 검색영역 -->
        <SearchArea />
      </div>

      <!-- side pannel icon -->
      <div class="side-menu">
        <div class="mm_group">
          <button
            v-for="item in treeMenuList"
            :key="item.id"
            type="button"
            class="mb_menu"
            @click="goLinkUrl(item)"
            :class="item.menuIcon"
            :data-menu="item.id"
          >
            <img
              :src="getIconSrc(item.menuIcon)"
              alt=""
              class="lnb_icon_box filter-themes"
              :style="{ filter: item.menuIcon === 'menu-favorite' ? 'none' : '' }"
            />
            <span class="m_txt">{{ item.menuNm }}</span>
          </button>
        </div>

        <!-- side pannel 모음 -->
        <div class="scrollbar-dynamic">
          <div class="ms_group">
            <div
              v-for="item in treeMenuList"
              :key="item.menuIcon + item.id"
              :id="`side_${item.id}`"
              class="ms_menu"
              :class="[item.menuTypeCd === enums.common.menuType.LINK.value ? '' : 'ms_smenu_gp', item.index === 0 ? 'on' : '']"
            >
              <div v-for="data in item.children" :key="`children${data.id}`" class="ms_menu_gp">
                <div class="ms_menu_txt">{{ data.menuNm }}</div>
                <ul>
                  <li
                    v-for="menu in data.children"
                    :key="data.id + menu.id"
                    class="smenu_txt"
                    style="cursor: pointer"
                    @click="goMenuUrlMobile(menu, $event)"
                  >
                    {{ menu.menuNm }}
                  </li>
                </ul>
              </div>
            </div>
            <div id="app1" :key="'app1' + item.id">
              <window-popup v-model="windowPopup" :urls="url">팝업창의 내용입니다.</window-popup>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <div class="side-top right_log">
        <button type="button" data-menu="" @click="toggleSidePannel">
          <img src="@/assets/images/lnb_icon1.png" alt="CSR System" />
        </button>
      </div>
      <!-- side pannel icon -->
      <div class="pannel-area" style="height: calc(100% - 60px)">
        <DxScrollView width="100%" height="100%">
          <ul>
            <li v-for="item in treeMenuList" :key="item.id" :class="[item.menuIcon, { sel: item.selected }]" @click="goLinkUrl(item)">
              <button type="button" class="side-obj" :data-menu="item.id">
                <img
                  :src="getIconSrc(item.menuIcon)"
                  alt=""
                  class="lnb_icon_box filter-themes"
                  :style="{ filter: item.menuIcon === 'menu-favorite' ? 'none' : '' }"
                />
                <span class="m_txt">{{ item.menuNm }}</span>
              </button>
            </li>
          </ul>
        </DxScrollView>
      </div>
      <!-- side pannel 모음 -->
      <div class="pannel-group">
        <div v-for="item in treeMenuList" :key="item.menuIcon + item.id">
          <div
            :id="`side_${item.id}`"
            :class="[item.menuTypeCd === enums.common.menuType.LINK.value ? '' : 'side-pannel', item.selected ? 'show' : '']"
          >
            <div class="pannel-cont">
              <DxScrollView width="100%" height="100%">
                <div class="tbl-acco">
                  <dl>
                    <div v-for="data in item.children" :key="data.menuNm + 'title' + data.id">
                      <dt :class="data.selected ? 'sel' : ''" @click="onSideDtClick($event, item.id)">
                        <a>
                          <span class="title">{{ data.menuNm }}</span>
                        </a>
                      </dt>
                      <dd :style="data.selected ? 'display: block;' : ''" :key="data.menuNm + 'submenu' + data.id">
                        <span
                          v-for="menu in data.children"
                          :key="data.id + menu.id"
                          class="cursor-pointer"
                          :class="menu.selected ? 'on' : ''"
                        >
                          <a @click="goMenuUrl(menu, $event)">
                            {{ menu.menuNm }}
                          </a>
                        </span>
                      </dd>
                    </div>
                  </dl>
                </div>
              </DxScrollView>
            </div>
          </div>
          <div id="app1" :key="'app1' + item.id">
            <window-popup v-model="windowPopup" :urls="url">팝업창의 내용입니다.</window-popup>
          </div>
        </div>
      </div>
    </template>
  </aside>
</template>

<script>
  import { DxScrollView } from 'devextreme-vue/scroll-view';
  import { isEmpty } from '@/plugins/common-lib';
  import WindowPopup from './window-popup.vue';
  import SearchArea from '@/layouts/search-area.vue';
  import enums from '@/configs/enums';

  const $ = window.$;

  export default {
    components: { DxScrollView, WindowPopup, SearchArea },
    data() {
      return {
        drawer: true,
        menuList: null,
        selectedItems: null,
        animationDuration: 300,
        multiple: true,
        collapsible: true,
        windowPopup: false,
        url: '',
        treeMenuList: [],
        menuIcons: [],
      };
    },
    computed: {
      enums() {
        return enums;
      },
    },
    watch: {
      treeMenuList() {
        // 사이드판넬 이벤트 추가
        this.$nextTick(() => {
          this.actSidePannel();
        });
      },
      '$store.getters.getFavorite': {
        handler(newValue, oldValue) {
          if (newValue?.length || oldValue?.length) {
            this.treeMenuList = this.setTreeMenuList();
          }
        },
        deep: true,
      },
      '$route.path'() {
        this.$nextTick(() => {
          this.treeMenuList = this.setTreeMenuList();
        });
      },
    },
    methods: {
      // 현재 경로에 따른 메뉴 리스트 선택 세팅
      setTreeMenuList() {
        const currentPath = this.$route.path;
        // 현재 경로에서 메뉴 ID를 추출
        let menuId = currentPath.substring(currentPath.lastIndexOf('/') + 1);

        // 보고서의 경우 pageUrl 대신 menuId를 사용해야 하기 때문에 형변환으로 타입 맞춰줌
        if (!isNaN(menuId)) {
          menuId = parseInt(menuId);
        }

        let menuList;
        if (this.$store.getters.getFavorite?.length) {
          const favoriteMenu = this.settingFavoriteMenu();
          const menuListFromStore = this.$store.getters.getMenuList;
          menuList = [...favoriteMenu, ...menuListFromStore];
        } else {
          menuList = this.$store.getters.getMenuList;
        }

        // 모든 메뉴 항목의 선택 상태 초기화
        menuList.forEach(d => (d.selected = false));

        // 2단계 메뉴 중 첫 번째 메뉴 선택
        const depth2List = menuList.filter(d => d.menuDepth === 2);
        const parentIds = new Set(depth2List.map(d => d.parentId));
        parentIds.forEach(parentId => {
          const firstChild = depth2List.find(d => d.parentId === parentId);
          if (firstChild) {
            firstChild.selected = true;
          }
        });

        // 경로에 따른 메뉴 선택 처리
        // 메뉴가 선택되지 않은 경우, 메뉴창 숨기기
        if (currentPath === '' || currentPath === '/') {
          this.$parent.getWrapElement().classList.remove('left-show');
        } else {
          // 현재 경로에 해당하는 메뉴 선택
          const target = menuList.find(d => d.id === menuId || d.pageUrl === currentPath);

          if (target) {
            // 경로 메뉴에 따른 상위 메뉴 선택
            const depth2 = menuList.find(d => d.id === target.parentId);
            const depth1 = depth2 && menuList.find(d => d.id === depth2.parentId);

            if (depth1 && depth2) {
              depth1.selected = true;
              depth2.selected = true;
              target.selected = true;

              // 2단계 메뉴 중 선택된 메뉴가 아닌 메뉴는 선택 해제, 만약 첫번째라면 조건에서 제외
              const foundDepth2List = depth2List.find(d => d.parentId === depth2.parentId);

              if (foundDepth2List && foundDepth2List.id !== depth2.id) {
                foundDepth2List.selected = false;
              } else {
                // 별도 메뉴가 존재하지 않는 세부항목일 경우 depth 추가
                const parentDepth1 = menuList.find(d => d.id === depth1.parentId);
                if (parentDepth1) {
                  parentDepth1.selected = true;
                }
              }
            }
          }
        }

        // 메뉴 데이터를 트리 구조로 변환하여 반환
        this.menuList = this.$_commonlib.makeTreeData(menuList.filter(d => d.menuDepth <= 3));
        return this.menuList;
      },

      closeMenu() {
        const menu = document.getElementById('myMenu');
        const backdrop = document.querySelector('.backdrop-menu');

        if (!menu.classList.contains('open')) {
          return false;
        }

        const closeMenuEvent = new CustomEvent('closeMenu', {
          detail: {
            menu: menu,
          },
        });
        document.dispatchEvent(closeMenuEvent);

        menu.classList.remove('open');
        if (backdrop) {
          backdrop.remove();
        }

        document.querySelectorAll('.header.side-menu-right').forEach(header => {
          header.classList.remove('side-menu-right');
        });

        document.body.classList.remove('side-menu');
      },
      // 클릭 시 메뉴 on 제거, 메뉴영역 활성화시엔 토글
      toggleSidePannel() {
        const menu = this.menuList[0];
        const isFirstMenuSelected = this.$route.path === '/' && !this.menuList[0].selected;
        if (isFirstMenuSelected) {
          this.goLinkUrl(menu);
          menu.selected = true;
        }

        this.$parent.getWrapElement().classList.toggle('left-show');
      },
      onSideDtClick(event, id) {
        const accoList1 = $(`#side_${id}.side-pannel`).find('.tbl-acco');
        const accoTitle1 = accoList1.find('dt');
        const accoCont1 = accoList1.find('dd');

        if ($(event.target).closest('dt').hasClass('sel')) {
          accoTitle1.removeClass('sel');
          accoCont1.slideUp(200);
        } else {
          accoTitle1.removeClass('sel');
          accoCont1.slideUp(200);
          $(event.target).closest('dt').addClass('sel');
          $(event.target).closest('dt').next('dd').slideDown(200);
        }
      },
      goLinkUrl(menu) {
        if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl) {
          this.windowPopup = true;
          this.url = menu.pageUrl.replace('#user#', this.$store.getters.getLoginId);
        }

        // 모바일에서 탭이동 이벤트 적용
        document.querySelectorAll('.ms_menu').forEach(item => item.classList.remove('on'));
        document.querySelector(`#side_${menu.id}`).classList.add('on');
      },
      goMenuUrl(menu, event) {
        if (event.ctrlKey) {
          this.windowPopup = true;
          if (menu.menuTypeCd === enums.common.menuType.REPORT.value) {
            //보고서 메뉴일 경우
            this.url = menu.pageUrl == null ? '/data-report' : menu.pageUrl;
          } else {
            this.url = menu.pageUrl;
          }
        } else {
          // 메뉴 이동 시, 하이라이트 추가
          $('.tbl-acco dd  SPAN').removeClass('on');
          event.currentTarget.parentNode.classList.add('on');

          if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl != null) {
            // 링크 메뉴
            this.windowPopup = true;
            this.url = this.makeUrl(menu.pageUrl);
          } else if (menu.menuTypeCd === enums.common.menuType.REPORT.value) {
            //보고서 메뉴일 경우
            let obj = {};
            let menuPageUrl = menu.pageUrl == null ? '/data-report' : menu.pageUrl;
            obj.path = `${menuPageUrl}/${menu.id}`;
            this.$router.push(obj);
          } else {
            this.$router.push(menu.pageUrl);
          }
        }
      },
      goMenuUrlMobile(menu, event) {
        // 메뉴 이동 시, 하이라이트 추가
        document.querySelectorAll('.ms_group .smenu_txt').forEach(item => item.classList.remove('on'));
        event.currentTarget.classList.add('on');

        this.closeMenu();

        if (event.shiftKey && event.ctrlKey) {
          this.windowPopup = true;
          if (menu.menuTypeCd === enums.common.menuType.REPORT.value) {
            //보고서 메뉴일 경우
            let menuPageUrl = menu.pageUrl == null ? '/data-report' : menu.pageUrl;
            this.url = `${menuPageUrl}/${menu.id}`;
          } else {
            this.url = menu.pageUrl;
          }
        } else {
          if (menu.menuTypeCd === enums.common.menuType.LINK.value && menu.pageUrl != null) {
            // 링크 메뉴
            this.windowPopup = true;
            this.url = menu.pageUrl;
          } else if (menu.menuTypeCd === enums.common.menuType.REPORT.value && menu.pageUrl == null) {
            //보고서 메뉴일 경우
            let obj = {};
            let menuPageUrl = menu.pageUrl == null ? '/data-report' : menu.pageUrl;
            obj.path = `${menuPageUrl}/${menu.id}`;
            this.$router.push(obj);
          } else {
            this.$router.push(menu.pageUrl);
          }
        }
      },
      // 클릭 시 메뉴 on 제거, 메뉴영역 활성화시엔 토글,
      actSidePannel() {
        const menuObj = $('.side-obj');
        const pnlObj = $('.side-pannel');
        const _$store = this.$store;

        menuObj.off('click').on('click', function () {
          // [클릭시] 왼쪽 2차 메뉴 호출
          $('#wrap').addClass('left-show');

          let pnlCate = $(this).data('menu');
          if ($(this).parent('li').hasClass('sel')) {
            /** 선택된 대메뉴로 다시 들어갈때 기존 펼처저 있는 메뉴 유지 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          } else {
            /** 선택된 대메뉴로 가 아닌 펼처야 할 메뉴 펼칠때 다른 메뉴위치 초기화 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');
            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $('#side_' + pnlCate).on('', function () {
              // 접근성 관련 작업 - 사이드패널 관련 focus 및 tabindex 추가
              $(this).find('a, button').attr({ tabIndex: '0' });
              $(this).find('a, button').eq(0).focus();
            });

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          }
        });

        menuObj.off('mouseenter, mouseover').on('mouseenter, mouseover', function () {
          // [마우스] 오버시 왼쪽 2차 메뉴 호출
          if (_$store.getters.getIsShowMyLayerLayout) return;

          $('#wrap').addClass('left-show');
          let pnlCate = $(this).data('menu');
          if ($(this).parent('li').hasClass('sel')) {
            /** 선택된 대메뉴로 다시 들어갈때 기존 펼처저 있는 메뉴 유지 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          } else {
            /** 선택된 대메뉴로 가 아닌 펼처야 할 메뉴 펼칠때 다른 메뉴위치 초기화 **/
            menuObj.parent('li').removeClass('sel');
            pnlObj.removeClass('show');

            switch (pnlCate) {
              case 'dashboards': // ECS 대시보드 슬라이드
                break;
              case 'operationmng': // ECS 운영관리 슬라이드
                break;
              case 'report': // ECS 보고서 슬라이드
                break;
              case 'monitoring': // ECS 모니터링 슬라이드
                break;
              case 'preferences': // ECS 환경설정 슬라이드
                break;
            }

            $('#side_' + pnlCate).on('', function () {
              // 접근성 관련 작업 - 사이드패널 관련 focus 및 tabindex 추가
              $(this).find('a, button').attr({ tabIndex: '0' });
              $(this).find('a, button').eq(0).focus();
            });

            $(this).parent('li').addClass('sel');
            $('#side_' + pnlCate).addClass('show');
          }

          // 접근성 관련 작업 - 사이드패널 관련 inter-action 추가
          $(document)
            .find('body')
            .off('mouseover.side-pannel')
            .on('mouseover.side-pannel', function (e) {
              // [마우스] 오버시 왼쪽 2차 메뉴 닫기
              if (!$('aside').has(e.target).length && !_$store.getters.getIsShowMyLayerLayout && !$('.popDim').has(e.target).length) {
                pnlObj.find('a, button').attr({ tabIndex: '-1' });
                $('#wrap').removeClass('left-show');
              }
            });
        });
      },

      showMyProfile() {
        let btnObj = $('header').find('.btn-profile');
        let profile_prop = false;
        btnObj.on('click', function () {
          if (!profile_prop) {
            profile_prop = true;
            $('header').find('.btn-profile').addClass('sel');
            $('header').find('.my-profile').addClass('show');
            $('.wrap.mobile_wrap').addClass('popdim_show');
            // 접근성 관련 작업 - tabindex 변경
            $('header').find('.my-profile').find('a').attr({ tabIndex: '0' });
          } else {
            profile_prop = false;
            $('header').find('.btn-profile').removeClass('sel');
            $('header').find('.my-profile').removeClass('show');
            $('.wrap.mobile_wrap').removeClass('popdim_show');
            // 접근성 관련 작업 - tabindex 변경
            $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
          }
          $(document)
            .on('click.myprofile')
            .on('click.myprofile', function (e) {
              if (
                !$('header .my-profile').has(e.target).length &&
                e.target.className.indexOf('btn-profile') < 0 &&
                e.target.className.indexOf('my-profile') < 0
              ) {
                profile_prop = false;
                $('header').find('.btn-profile').removeClass('sel');
                $('header').find('.my-profile').removeClass('show');
                $('.wrap.mobile_wrap').removeClass('popdim_show');
                // 접근성 관련 작업 - tabindex 변경
                $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
              }
            });
          // 접근성 관련 작업 - 내정보 포커스 관련 inter-action 추가
          $(document)
            .find('body')
            .off('focus.myprofile')
            .on('focus.my-profile', function (e) {
              if (!$('.my-profile').has(e.target).length && e.target.className.indexOf('btn-profile') < 0) {
                profile_prop = false;
                $('header').find('.btn-profile').removeClass('sel');
                $('header').find('.my-profile').removeClass('show');
                $('.wrap.mobile_wrap').removeClass('popdim_show');
                $('header').find('.my-profile').find('a').attr({ tabIndex: '-1' });
              }
            });
        });
      },
      /**
       * 즐겨찾기 메뉴 설정
       *
       * @return {*}
       */
      settingFavoriteMenu() {
        const menuList = this.$store.getters.getMenuList || [];
        const favoriteParentMenu = {
          id: 99999999,
          menuDepth: 1,
          menuNm: '즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: 'menu-favorite',
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: null,
        };
        const favoriteParentMenu2 = {
          id: 999999999,
          menuDepth: 2,
          menuNm: '나의 즐겨찾기',
          menuOrd: -1,
          menuTypeCd: enums.common.menuType.NORMAL_MENU.value,
          menuIcon: null,
          viewFl: enums.common.stringViewFlag.YES.value,
          parentId: favoriteParentMenu.id,
        };

        return this.$store.getters.getFavorite
          .map(item => item.menuId)
          .reduce(
            (result, id) => {
              const findMenu = menuList.find(menu => menu.id === id);
              if (!isEmpty(findMenu)) {
                result.push({ ...findMenu, parentId: favoriteParentMenu2.id, menuDepth: 3 });
              }
              return result;
            },
            [favoriteParentMenu, favoriteParentMenu2],
          );
      },
      getStoreData(keys) {
        const storeState = this.$store.state;
        const result = {};
        keys.forEach(key => {
          if (storeState.hasOwnProperty(key)) {
            result[key] = storeState[key];
          }
        });
        return result;
      },
      getUrl(url, type) {
        const obj = new URL(url);
        return obj[type];
      },
      makeQuery(data) {
        const params = new URLSearchParams(data);
        return params.toString();
      },
      makeUrl(url) {
        const keys = this.getUrl(url, 'search').replace('?', '').split(',');
        const hardQueryString = keys.filter(key => key.indexOf('=') > -1);
        const hardKeys = hardQueryString.reduce((map, item) => {
            const [key, value] = item.split('=');
            map[key] = value;
            return map;
        }, {});
        const data = this.getStoreData(keys);
        const queryString = this.makeQuery({...data, ...hardKeys});
        const port = this.getUrl(url, 'port') ? ':' + this.getUrl(url, 'port') : '';
        return this.getUrl(url, 'protocol') + '//' + this.getUrl(url, 'hostname') + port + '?' + queryString;
      },
      /**
       * 메뉴 아이콘 SRC 반환
       *
       * @param value
       * @return {*}
       */
      getIconSrc(value) {
        if (!value) {
          return '';
        }

        const icon = this.menuIcons.find(icon => icon.value === value);
        if (icon) {
          return icon?.src;
        } else if (value === 'menu-favorite') {
          return require('@/assets/images/menu-favorite.svg');
        }
        return '';
      },
    },
    mounted() {
      this.menuIcons = this.$_theme.menuIcons;
      this.treeMenuList = this.setTreeMenuList();
      this.showMyProfile();
    },
  };
</script>

<style scoped>
  .themes_gp_style01 .wrap .pannel-group,
  .themes_gp_style02 .wrap .pannel-group,
  .themes_gp_style04 .wrap .pannel-group,
  .themes_gp_style05 .wrap .pannel-group,
  .themes_gp_style03 .wrap .pannel-group,
  .themes_gp_style06 .wrap .pannel-group {
    display: none;
  }

  .themes_gp_style01 .wrap.left-show .pannel-group,
  .themes_gp_style02 .wrap.left-show .pannel-group,
  .themes_gp_style03 .wrap.left-show .pannel-group,
  .themes_gp_style04 .wrap.left-show .pannel-group,
  .themes_gp_style05 .wrap.left-show .pannel-group,
  .themes_gp_style06 .wrap.left-show .pannel-group {
    display: block;
  }

  .side-obj:after {
    content: '';
    position: absolute;
    bottom: -14px;
    left: 14px;
    width: 44px;
    height: 1px;
    background: #e9ecef;
  }
</style>
