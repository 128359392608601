/**
 * @Path: src/api/creator.js
 * @Description: API 호출을 위한 axios 인스턴스 생성 및 설정
 */
import axios from 'axios';
import store from '@/store';
import router from '@/router';
import i18n from '@/plugins/vue-i18n';
import {workHistoryApi} from "@/api/index";

/**
 *  서버 인스턴스 생성
 *  @param baseURL - 서버 URL
 *  @param {boolean} withCredentials - 쿠키 전송 여부
 *  @param {number} timeout - 요청 타임아웃
 *  @return {AxiosInstance}
 * */
export const createAxiosInstance = (baseURL, timeout = 5000, withCredentials = true) => {
	const serverInstance = axios.create({
		baseURL,
		timeout,
		withCredentials,
	});

	// Request Interceptor
	serverInstance.interceptors.request.use(config => {
		// API 호출시 Headers 토큰 설정
		if (config?.method !== 'get') {
			const payload = {
				data: {
					menuId: config?.headers?.menuId,
					actionNm: config?.headers?.actionNm,
					content: JSON.stringify(config?.data?.workLog?.content),
					preContent: JSON.stringify(config?.data?.workLog?.preContent),
				},
			};

			if (payload?.data?.menuId !== null) {
				workHistoryApi.addWorkHistory(payload);
			}
			config.data = config?.data?.workLog ? config.data.data : config.data;
		}
		return config;
	}, handleRequestError);

	serverInstance.interceptors.response.use(
		response => {
			return Promise.resolve(response);
		},
		error => handleResponseError(error),
	);

	return serverInstance;
};

/**
 * 서버 요청 에러 핸들링 함수
 * @param {object} error - 에러 객체
 * @returns {Promise}
 * */
const handleRequestError = error => {
	console.error(error);
	return Promise.reject(error);
};

/**
 *  서버 응답 에러 핸들링 함수
 *  @param {object} error - 에러 객체
 *  @returns {Promise}
 * */
const handleResponseError = async error => {
	if (error.code === 'ECONNABORTED') {
		const timeout = error.config.timeout;
		error.message = i18n.t('COMMON.MESSAGE.CMN_ERROR_TIMEOUT', { timeout: timeout });
		return Promise.reject(error);
	}

	const { status, data } = error.response;

	// 토큰 만료시 로그아웃 처리
	if (status === 401) {
		await store.dispatch('LOGOUT', {
			data: {
				loginId: store.getters.getLoginId,
				continue: router.history.current.path,
			},
		});
	}
	return Promise.reject(error);
};

/**
 * API 호출 함수
 * @param payload - API 호출 정보
 * @param serverInstance - axios instance
 * @param config - axios config
 * @return {*}
 */
export const createCallApi = (payload, serverInstance, config) => {
	if (serverInstance.defaults.baseURL === '') {
		serverInstance.defaults.baseURL = payload.host;
	}
	serverInstance.defaults.timeout = payload.timeout;

	switch (payload.method) {
		case 'POST':
			return serverInstance.post(`${payload.path ? payload.path : ''}`, payload?.data, config);
		case 'PUT':
			return serverInstance.put(`${payload.path ? payload.path : ''}`, payload?.data, config);
		case 'DELETE': {
			// DELETE 요청시 data 속성에 data 객체가 없을 경우 data 속성 추가
			// headers 속성은 payload에 포함 시켜야 정상 동작함
			const deletePayload = {
				headers: config.headers,
				data: payload?.data?.data ? payload.data.data : payload?.data,
			};
			return serverInstance.delete(`${payload.path ? payload.path : ''}`, deletePayload);
		}
		default:
			return serverInstance.get(`${payload.path ? payload.path : ''}?${new URLSearchParams(payload?.data)}`, config);
	}
};
