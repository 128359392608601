import axios from 'axios';
import {Base64} from 'js-base64';
import router from '../router';
import store from '../store';
import i18n from '@/plugins/vue-i18n';

export { ucApi } from './instance/uc';
export { letteringApi } from './instance/lettering';
export { reportApi } from './instance/report';
export { ccApi } from './instance/cc';
export { ewmApi } from './instance/ewm';
export { llmApi } from './instance/llm';
export { forecastApi } from './instance/forecast';
export { emcApi } from './instance/emc';

/**
 * @description axios config 생성 함수
 * @param payload
 * @return {any}
 */
const makeConfig = payload => {
	let config = {
		headers: {
			Authorization: 'Bearer ' + store.getters.getAccessToken,
			loginId: store.getters.getLoginId,
			loginNm: Base64.encode(store.getters.getLoginNm),
			actionNm: payload?.actionname,
			menuId: setMenuId(),
		},
		responseType: payload?.responseType,
		onDownloadProgress: payload?.onDownloadProgress,
	};

	if (payload?.headers) {
    config.headers = { ...config.headers, ...payload.headers };
  }
	return config;
};

/**
 * @description 메뉴 ID 설정 함수 작업이력 적재시 필요한 메뉴 ID를 설정한다.
 * @return {*|null}
 */
const setMenuId = () => {
	let menu = null;
	if (store.getters.getMenuList.length > 0) {
		menu = router.history.current.params.menuid
			? store.getters.getMenuList.find(d => d.id === parseInt(router.history.current.params.menuid))
			: store.getters.getMenuList.find(d => d.pageUrl === router.history.current.path);
		return menu ? menu.id : null;
	}
	return null;
};

/**
 * @description 작업이력 axios 인스턴스
 * @type workHistoryInstance axios instance
 */
const workHistoryInstance = axios.create({
	baseURL: process.env?.VUE_APP_ESP_SERVER_URL ? process.env.VUE_APP_ESP_SERVER_URL : '',
	withCredentials: true,
	timeout: 5000,
});
workHistoryInstance.interceptors.response.use(response => {
		return Promise.resolve(response);
	},
	async error => {
		console.log('error', error);
	},
);

/**
 * @description ESP 서버 axios 인스턴스
 * @type espServerInstance axios instance
 */
const espServerInstance = axios.create({
	baseURL: process.env?.VUE_APP_ESP_SERVER_URL ? process.env.VUE_APP_ESP_SERVER_URL : '',
	withCredentials: true,
	timeout: 5000,
});
espServerInstance.interceptors.request.use(function (config) {
	// GET 요청이 아닐 경우 작업이력 적재X
	if (config?.method !== 'get') {
		const payload = {
			data: {
				menuId: config?.headers?.menuId,
				actionNm: config?.headers?.actionNm,
				content: JSON.stringify(config?.data?.workLog?.content),
				preContent: JSON.stringify(config?.data?.workLog?.preContent),
			},
		};

		if (payload?.data?.menuId !== null) {
      workHistoryApi.addWorkHistory(payload);
    }
		config.data = config?.data?.workLog ? config.data.data : config.data;
	}
	return config;
});
espServerInstance.interceptors.response.use(
	response => {
		return Promise.resolve(response);
	},
	async error => {
		if (error.code === 'ECONNABORTED') {
			const timeout = error.config.timeout;
			error.message = i18n.t('COMMON.MESSAGE.CMN_ERROR_TIMEOUT', { timeout: timeout });
			return Promise.reject(error);
		}

		const {
			response: { status, request },
		} = error;

		// 토큰 만료시 로그아웃 처리
		if (status === 401) {
			await store.dispatch('LOGOUT', {
				data: {
					loginId: store.getters.getLoginId,
					continue: router.history.current.path,
				},
			});
		}
		return Promise.reject(error);
	},
);

/**
 * @description 작업이력 API 호출 함수
 * @type workHistoryApi axios instance API
 */
export const workHistoryApi = {
  workHistoryInstance,

  /**
   * 작업이력 목록 저장
   * @param payload
   * @return Promise
   */
  async addWorkHistory(payload) {
    const config = makeConfig(payload);
    if (config.headers.Authorization == null
			|| config.headers.Authorization === ''
			|| config.headers.Authorization === 'Bearer null') {
      return;
    }
    return workHistoryInstance.post(`/esp/api/v1/work-history`, payload.data, config);
  },
};

/**
 * @description ESP API 호출 함수
 */
export const espApi = {
	espServerInstance,
	/**
	 * @description API URL 목록 조회
	 * @return Promise
	 */
	async getRoutingInfoList() {
		// TODO : API URL store 갱신 전에 호출하는 API로 URL 하드코딩 되어있음.
		try {
			return await espServerInstance.get(`/esp/api/v1/system/routing/info`, makeConfig({}));
		} catch (error) {
			return error.response || error
		}
	},
	/**
	 * @description API 호출
	 * @param payload
	 * @return Promise
	 */
	async callApi(payload) {
		// API SERVER 를 등록하지 않으면 현재 서버로 설정
		if (espServerInstance.defaults.baseURL === '') {
			espServerInstance.defaults.baseURL = payload.host;
		}
		espServerInstance.defaults.timeout = payload.timeout;

		// FIXME : params 삭제 전 임시 코드
		if (payload?.data?.params) {
			payload.data = payload.data.params;
		}

		const config = makeConfig(payload);
		espServerInstance.defaults.headers.common['Authorization'] = config.headers?.Authorization || '';

		const path = payload.path ? payload.path : '';
		switch (payload.method) {
			case 'POST':
				return espServerInstance.post(path, payload?.data, config);
			case 'PUT':
				return espServerInstance.put(path, payload?.data, config);
			case 'DELETE': {
				// DELETE 요청시 data 속성에 data 객체가 없을 경우 data 속성 추가
				// headers 속성은 payload에 포함 시켜야 정상 동작함
				const deletePayload = {
					headers: config.headers,
					data: payload?.data?.data ? payload.data.data : payload?.data,
				};
				return espServerInstance.delete(path, deletePayload);
			}
			default:
				return espServerInstance.get(`${path}?${new URLSearchParams(payload?.data)}`, config);
		}
	},

	/**
	 * @description 로그인 요청
	 * @param payload
	 * @return Promise
	 */
	async getLogin(payload) {
		if (espServerInstance.defaults.headers?.common?.Authorization) {
			delete espServerInstance.defaults.headers.common.Authorization;
		}
		try {
			return await espServerInstance.post('/esp/api/v1/auth/login', payload);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 토큰 및 중복로그인 체크 요청
	 * @param payload
	 * @return Promise
	 */
	async getLoginAnotherCheck(payload) {
		let config = makeConfig();
		if (config?.headers?.Authorization) {
			config.headers.Authorization = payload?.accessToken;
		}

		try {
			return await espServerInstance.get('/esp/api/v1/auth/check', config);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 토큰 재발급 요청
	 * @return Promise
	 */
	async getToken() {
		try {
			return await espServerInstance.get(`/esp/api/v1/auth/token`);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 로그아웃 요청
	 * @param payload
	 * @return Promise
	 */
	async getLogout(payload) {
		try {
			return await espServerInstance.get(`/esp/api/v1/auth/logout?${new URLSearchParams(payload?.data)}`);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 패스워드 변경 요청
	 * @param payload
	 * @return Promise
	 */
	async getPasswdChg(payload) {
		try {
			return await espServerInstance.post(`/esp/api/v1/members/password-change`, payload, makeConfig());
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 시스템 설정 정보 요청
	 * @return Promise
	 */
	async getSystemSettings() {
		try {
			return await espServerInstance.get(`/esp/api/v1/system/setting`);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * @description 테마 설정 정보 요청
	 * @return Promise
	 */
	async getThemeSettings() {
		const payload = {
			includeBase64: true,
			// @FIXME : theme 설정 다시 설계 필요
			themeId: 'system',
		};
		try {
			return await espServerInstance.get(`/esp/api/v1/system/ui/theme?${new URLSearchParams(payload)}`);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * 파일 다운로드 요청
	 * @param payload
	 * @returns Promise
	 */
	async getFileDownload(payload) {
		try {
			return await espServerInstance.get(`/esp/api/v1/file/group/download/${payload.fileGroupId}/${payload.fileName}`, {
        responseType: 'blob',
      });
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * 파일 업로드 요청
	 *
	 * @param files {Array<File | Blob>}
	 * @return {Promise<*>}
	 */
	async fileUpload(files) {
		const formData = new FormData();
		if (files) {
			files.forEach(file => {
				formData.append('file', file, file.name);
			});
		}

		try {
			const config = makeConfig();
			config.headers['Content-Type'] = 'multipart/form-data';
			config.headers.actionNm = 'COMMON_ATTACHED_FILE_UPLOAD';
			return espServerInstance.post(`/esp/api/v1/file/group/file-upload`, formData, config);
		} catch (error) {
			return error.response || error
		}
	},

	/**
	 * 다이렉트 로그인 요청
	 * @param payload
	 * @return Promise
	 */
	async getDirectLogin(payload) {
		if (espServerInstance.defaults.headers?.common?.Authorization) {
			delete espServerInstance.defaults.headers.common.Authorization;
		}
		try {
			return await espServerInstance.post('/esp/api/v1/auth/login/direct', payload);
		} catch (error) {
			return error.response || error
		}
	},
};

export { makeConfig, setMenuId };
